import { createClient as createElectronClient } from '@openphone/desktop-client'
import * as Sentry from '@sentry/react'
import { createRoot } from 'react-dom/client'
import { RelayEnvironmentProvider } from 'react-relay'
import { RouterProvider, createBrowserRouter } from 'react-router-dom'

import App from './app/App'
import AppStore from './app/AppStore'
import { AppContext } from './app/context'
import { SentryManager } from './app/error'
import config, { deviceId, platform } from './config'
import { createWorkerQLRelayEnvironment } from './graphql/client'
import { extendDayJs } from './lib/date'
import log, { logError } from './lib/log'
import Service from './service'
import Worker from './service/worker?worker&url'

extendDayJs()

async function loadApp() {
  SentryManager.initialize()

  const client = createElectronClient() ?? null
  const desktopVersion = client?.version
  const { environment: relayEnvironment, socket } = await createWorkerQLRelayEnvironment({
    meta: {
      platform: platform || 'browser',
      desktopVersion,
      deviceId,
      envOverrides: {
        OP_ENV: config.ENV,
        OP_SIMULATE_MAINTENANCE_MODE: String(config.SIMULATE_MAINTENANCE_MODE),
      },
    },
  })

  const service = new Service(
    {
      workerName: Worker,
    },
    desktopVersion,
    socket,
  )

  const prevOnError = window.onerror
  window.onerror = function (message: string | Event, ...args: any[]) {
    // Benign error that can be ignored https://stackoverflow.com/a/50387233
    if (message === 'ResizeObserver loop limit exceeded') {
      return
    }

    log.error(typeof message === 'string' ? message : 'Unknown Error', args)

    if (prevOnError) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return prevOnError(message, ...args)
    }

    return false
  }

  // TODO: UXP-3093 - implement impersonation for the new auth flow
  // currently, we only support impersonation for the old auth flow
  //
  // handles impersonation using old auth flowResult
  // note: needs to run before we initialize any of the auth stores
  // to void any race conditions around initialization
  async function handleImpersonation(): Promise<void> {
    const urlObj = new URL(window.location.href)
    const params = new URLSearchParams(urlObj.search)
    const session_id_token = params.get('session_id_token')
    const session_refresh_token = params.get('session_refresh_token')

    if (session_id_token) {
      await service.storage.clearAll()
      await service.storage
        .async()
        .set(
          { idToken: session_id_token, refreshToken: session_refresh_token },
          'AuthStore.session',
        )

      // impersonation is limited to the lifetime of the id token
      // we do not want to refresh it
      if (!session_refresh_token) {
        service.storage.sync().set('AuthStore._isImpersonating', 'true')
      }

      params.delete('session_id_token')
      params.delete('session_refresh_token')

      urlObj.search = params.toString()
      const updatedUrl = urlObj.toString()

      window.location.href = updatedUrl
    }
  }

  await handleImpersonation()

  await Promise.all([service.flags.waitUntilReady(), service.auth.init()])

  const isUniversalLoginEnabled = service.flags.flags.webUniversalLogin
  const canOffboardFromLegacyAuth =
    service.flags.flags.webUniversalLoginOffboardFromLegacyAuth

  // offboarding a user from the legacy auth and preparing for the Universal Login
  if (
    isUniversalLoginEnabled &&
    canOffboardFromLegacyAuth &&
    service.auth.hasSession &&
    !service.auth.isImpersonating
  ) {
    Sentry.captureMessage(
      'Logging out the user due to migrating to Universal Login',
      'debug',
    )

    await service.clearAllAndRestart().catch(logError)
    return
  }

  // activate Universal Login flow if available
  if (service.authV2.isEnabled) {
    await service.authV2.init()

    // if the session is nearing expiration, we should reset it
    // to avoid any issues with unexpected logout in the future
    if (service.authV2.isSessionAboutToExpire) {
      Sentry.captureMessage(
        'Logging out the user (Universal Login) since their session is about to expire',
        'debug',
      )

      await service.clearAllAndRestart({ logoutReason: 'transition-to-v2' })
      return
    }
  }

  const router = createBrowserRouter([
    {
      path: '*',
      element: <App />,
    },
  ])

  const store = new AppStore(client, router, service)

  const element = document.getElementById('root')

  if (!element) {
    throw new Error('Could not find root element')
  }

  const root = createRoot(element)

  root.render(
    <AppContext.Provider value={store}>
      <RelayEnvironmentProvider environment={relayEnvironment}>
        <RouterProvider router={router} future={{ v7_startTransition: true }} />
      </RelayEnvironmentProvider>
    </AppContext.Provider>,
  )
}

// eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
loadApp()
