import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'

import { AppAvatar } from '@src/app/components'
import ItemEditor from '@src/app/contact/ItemEditor'
import { defaultNames } from '@src/app/contact/constants'
import CustomItem from '@src/app/contact/item/custom-item'
import ReadonlyItem from '@src/app/contact/item/readonly-item'
import { useAppStore } from '@src/app/context'
import { ScrollView } from '@src/component/scrollview'
import type { VCard } from '@src/lib/vcard'
import { fromFile, fromUrl } from '@src/lib/vcard'
import type { MessageMediaModel } from '@src/service/model'
import { ContactItemModel } from '@src/service/model/contact/ContactItemModel'
import { ContactModel } from '@src/service/model/contact/ContactModel'
import { LoadingIndicator } from '@ui/LoadingIndicator'
import TextWrap from '@ui/TextWrap'
import Typography from '@ui/Typography'
import { HStack, VStack } from '@ui/layout/Stack'

import * as styles from './VCardViewer.css'

interface VideoViewerProps {
  media: MessageMediaModel
}

const VideoViewer = function ({ media }: VideoViewerProps) {
  const store = useAppStore()
  const [loading, setLoading] = useState<boolean>(true)
  const [contact, setContact] = useState<ContactModel | null>(null)

  useEffect(() => {
    const abortController = new AbortController()

    async function loadContact() {
      let vcard: VCard | null = null

      if (media.url) {
        vcard = await fromUrl(media.url, { signal: abortController.signal })
      } else if (media.file) {
        vcard = await fromFile(media.file)
      }

      setLoading(false)

      if (!vcard) {
        return
      }

      const contact = new ContactModel(store.service.contact, {
        firstName: vcard.fn?.split(' ')[0],
        lastName: vcard.fn?.split(' ')[1],
        pictureUrl: vcard.photo,
        company: vcard.org,
        role: [vcard.title, vcard.role].join(' - '),
      })

      const items = ((): ContactItemModel[] =>
        [
          vcard.birthday
            ? new ContactItemModel(store.service.contact, contact, {
                type: 'date',
                value: vcard.birthday,
                name: 'Birthday',
              })
            : [],
          (vcard.phone ?? []).map(
            (phone) =>
              new ContactItemModel(store.service.contact, contact, {
                type: 'phone-number',
                value: phone.value,
                name: phone.name,
              }),
          ),
          (vcard.email ?? []).map(
            (phone) =>
              new ContactItemModel(store.service.contact, contact, {
                type: 'email',
                value: phone.value,
                name: phone.name,
              }),
          ),
          (vcard.address ?? []).map(
            (phone) =>
              new ContactItemModel(store.service.contact, contact, {
                type: 'address',
                value: phone.value,
                name: phone.name,
              }),
          ),
          (vcard.url ?? []).map(
            (url) =>
              new ContactItemModel(store.service.contact, contact, {
                type: 'url',
                value: url.value,
                name: url.name,
              }),
          ),
        ].flat())()

      contact.localUpdate({ items })

      setContact(contact)
    }

    void loadContact()

    return () => abortController.abort()
  }, [media.url, media.file, store])

  if (loading) {
    return (
      <div className={styles.wrapper}>
        <LoadingIndicator variant="circular" size={48} />
      </div>
    )
  }

  if (!contact) {
    return null
  }

  return (
    <div
      className={styles.wrapper}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          store.closeMediaViewer()
        }
      }}
    >
      <div className={styles.root} aria-label={`${contact.fullName} vcard`}>
        <ScrollView>
          <VStack className={styles.container} gap={20}>
            <HStack gap={16}>
              <AppAvatar size={80} identity={contact} />
              <Typography color="textPrimary" variant="title1">
                <TextWrap value={contact.fullName}>{contact.fullName}</TextWrap>
              </Typography>
            </HStack>
            <VStack gap={10} alignItems="stretch">
              <ItemEditor>
                {contact.company ? (
                  <ReadonlyItem type="company" name="Company" value={contact.company} />
                ) : null}
                {contact.role ? (
                  <ReadonlyItem type="role" name="Role" value={contact.role} />
                ) : null}
                {contact.items.map((item) => (
                  <CustomItem
                    contact={contact}
                    contactItem={item}
                    defaultName={item.type ? defaultNames[item.type] : ''}
                    placeholder={null}
                    editingId={null}
                    isReadOnly
                  />
                ))}
              </ItemEditor>
            </VStack>
          </VStack>
        </ScrollView>
      </div>
    </div>
  )
}

export default observer(VideoViewer)
