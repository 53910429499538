/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */
import { NotFoundError } from '@openphone/internal-api-client'
import dayjs from 'dayjs'
import { action, makeAutoObservable, runInAction } from 'mobx'
import type { Subscription } from 'rxjs'
import { Subject } from 'rxjs'

import type { InboxFiltersController } from '@src/app/inbox/conversations/InboxFilters'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull, { assertIsNotUndefined } from '@src/lib/isNonNull'
import log, { logError } from '@src/lib/log'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import makePersistable from '@src/service/storage/makePersistable'
import type {
  ConversationParticipantStatus,
  Viewer,
} from '@src/service/transport/communication'

import type Service from '.'
import ConversationHistoryService from './conversation/ConversationHistoryService'
import type {
  SearchContactItemTopic,
  SearchConversationsRequest,
} from './dto/request/search/SearchConversationsRequest'
import type { SearchConversationsResponse } from './dto/response/search/SearchConversationsResponse'
import { ActivityModel, ConversationModel } from './model'
import type { IActivity, CodableConversation } from './model'
import type { ActivitySearchParams } from './transport/communication'
import type { LegacyPageInfo } from './transport/lib/LegacyPaginated'
import type {
  ActivityUpdateMessage,
  ConversationUpdateMessage,
  ParticipantStatusMessage,
} from './transport/websocket'
import type MainWorker from './worker/main'
import type { ConversationRepository } from './worker/repository/conversation'

export type ConversationQuery = {
  phoneNumberId?: string
  directNumberId?: string
  phoneNumber: string
  canBeNew?: boolean
}

export type ConversationsQuery = {
  phoneNumberId?: string
  directNumberId?: string
  snoozed?: boolean
  read?: boolean
  last?: number
}

type ConversationId = string
type UserId = string

type PresenceDetails = {
  status: ConversationParticipantStatus
  timestamp: number
}
export type ConversationPresence = {
  [key: UserId]: PresenceDetails
}

export type ConversationsPresence = {
  [key: ConversationId]: ConversationPresence
}

export default class ConversationStore {
  readonly collection: PersistedCollection<ConversationModel, ConversationRepository>
  readonly historyService: ConversationHistoryService

  private lastFetchedAt: { [key: string]: number } = {}
  private pageInfos: { [key: string]: LegacyPageInfo } = {}

  unreadCounts: Record<string, number> = {}
  private unreadCountSubscription: Subscription | null = null

  private updateTimeout: number | null = null
  private batchedConversationUpdates: CodableConversation[] = []
  private batchedConversationDeletes: string[] = []
  private batchedActivityUpdates: IActivity[] = []
  private disposeBag = new DisposeBag()

  protected change$ = new Subject<ConversationModel[]>()
  protected delete$ = new Subject<CodableConversation[]>()

  presence: ConversationsPresence = {}

  constructor(
    private root: Service,
    private worker: MainWorker,
  ) {
    this.collection = new PersistedCollection({
      table: root.storage.table('conversation'),
      classConstructor: (json: CodableConversation) => new ConversationModel(root, json),
    })

    this.historyService = new ConversationHistoryService(root.activity, root.transport, {
      itemsPerPage: 50,
    })

    makeAutoObservable(this, {})

    makePersistable<this, 'lastFetchedAt' | 'pageInfos'>(this, 'ConversationStore', {
      lastFetchedAt: root.storage.sync(),
      pageInfos: root.storage.async(),
    })

    this.disposeBag.add(this.handleActivityStoreUpdates(), this.handleWebsocket())
  }

  onChange = (callback: (conversations: ConversationModel[]) => void): Subscription => {
    return this.change$.subscribe(callback)
  }

  onDelete = (callback: (conversations: CodableConversation[]) => void): Subscription => {
    return this.delete$.subscribe(callback)
  }

  async fetchRecent(params: ConversationsQuery) {
    // fetch the first 200 convos
    const key = this.keyForQuery(params)
    const lastFetchedAt = this.lastFetchedAt[key]
    const since = lastFetchedAt ? new Date(lastFetchedAt) : undefined
    const filters = since
      ? { directNumberId: params.directNumberId, phoneNumberId: params.phoneNumberId }
      : params

    const response = await this.root.transport.communication.conversations.list({
      ...filters,
      since,
      includeDeleted: Boolean(since),
    })

    const convos = await this.load(response.result)

    const allConvosLastFetchedAt = convos.reduce<number[]>((result, current) => {
      if (current.updatedAt) {
        result.push(current.updatedAt)
      }
      return result
    }, [])

    runInAction(() => {
      this.lastFetchedAt[key] = Math.max(lastFetchedAt || 0, ...allConvosLastFetchedAt)
    })

    if (!since) {
      runInAction(() => {
        this.pageInfos[key] = response.pageInfo
      })
    }
  }

  /**
   * Fetches the conversations that match the applied filters
   */
  async fetchFiltered(
    phoneNumberId: string[],
    filtersController: InboxFiltersController,
  ): Promise<ConversationModel[]> {
    const payload = this.makeFilteredPayload(phoneNumberId, filtersController)

    if (payload.filter?.length === 0) {
      return []
    }

    const response = await this.root.transport.search.conversation(payload)
    return this.makeConversationsFromResponse(response)
  }

  private makeFilteredPayload(
    phoneNumberId: string[],
    filtersController: InboxFiltersController,
  ): SearchConversationsRequest {
    const filter: SearchConversationsRequest['filter'] = []

    if (filtersController.isActive('conversationStatus')) {
      const conversationStatusFilter =
        filtersController.filters.getByType('conversationStatus')[0]
      // @ts-expect-error unchecked index access
      const { value } = conversationStatusFilter
      filter.push({ topic: 'conversation.isDone', value: value === 'done' })
    }

    if (filtersController.isActive('unread')) {
      filter.push({ topic: 'conversation.isUnread', value: true })
    }

    if (filtersController.isActive('unreplied')) {
      filter.push({ topic: 'conversation.isUnreplied', value: true })
    }

    if (filtersController.isActive('company')) {
      const companyFilter = filtersController.filters.getByType('company')[0]
      if (companyFilter) {
        const { value, operator } = companyFilter

        if (value.length > 0) {
          assertIsNotUndefined(value[0])
          switch (operator) {
            case 'is':
            case 'is not': {
              filter.push({
                topic: 'contact.company',
                op: operator,
                value: value[0],
              })

              break
            }

            case 'is empty':
            case 'is not empty': {
              filter.push({
                topic: 'contact.company',
                op: operator,
              })

              break
            }

            case 'is one of':
            case 'is none of': {
              filter.push({
                topic: 'contact.company',
                op: operator,
                value: value,
              })

              break
            }
          }
        }
      }
    }

    if (filtersController.isActive('tag')) {
      const tagFilters = filtersController.filters.getByType('tag')

      for (const tagFilter of tagFilters) {
        const { value, operator, id } = tagFilter

        if (value.length > 0) {
          assertIsNotUndefined(value[0])
          const template = this.root.contact.template.get(id)

          if (!template?.key) {
            continue
          }

          const base: Omit<SearchContactItemTopic, 'op' | 'value'> = {
            topic: 'contact.item',
            templateKey: template.key,
            type: 'multi-select',
          }

          switch (operator) {
            case 'is':
            case 'is not': {
              filter.push({
                ...base,
                op: operator,
                value: value[0],
              })

              break
            }

            case 'is empty':
            case 'is not empty': {
              filter.push({
                ...base,
                op: operator,
              })

              break
            }

            case 'is one of':
            case 'is all of':
            case 'is none of': {
              filter.push({
                ...base,
                op: operator,
                value: value,
              })

              break
            }
          }
        }
      }
    }

    return {
      phoneNumberId,
      filter,
    }
  }

  private makeConversationsFromResponse(response: SearchConversationsResponse) {
    const conversations: ConversationModel[] = []

    if (!response.conversations) {
      return conversations
    }

    for (const conversation of response.conversations) {
      const conversationInstance = this.getOrMakeConversation(conversation)
      conversations.push(conversationInstance)
    }

    return conversations
  }

  private getOrMakeConversation(conversation: CodableConversation) {
    const savedConversation = this.collection.get(conversation.id)

    if (savedConversation) {
      return savedConversation
    }

    const conversationInstance = new ConversationModel(this.root, {
      ...conversation,
    })

    this.collection.put(conversationInstance)
    return conversationInstance
  }

  hasMore = (params: ConversationsQuery): boolean => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    return !pageInfo || pageInfo?.hasPreviousPage == true
  }

  /**
   * Tries to fetch a conversation from the api given its id, if the conversation is found
   * it will store it and return it back.
   */
  fetchConversation = async (
    conversationId: string,
  ): Promise<ConversationModel | null> => {
    const { conversation, result: activities } =
      await this.root.transport.communication.activities
        .list({
          id: conversationId,
        })
        .catch(() => ({ conversation: null, result: null }))

    if (!conversation || !activities) {
      return null
    }

    const [storedConversation] = await this.load([conversation], activities)

    return storedConversation ?? null
  }

  fetchActiveViewersForConversations = async (conversationIds: string[]) => {
    const activeViewersByConversationId =
      await this.root.transport.communication.conversations.fetchActiveViewersForConversations(
        conversationIds,
      )

    if (!activeViewersByConversationId) {
      return
    }

    for (const [conversationId, conversationViewers] of Object.entries(
      activeViewersByConversationId,
    )) {
      this.updateViewersPresence(conversationId, conversationViewers)
    }
  }

  fetchViewersForConversation = async (conversationId: string) => {
    const viewers =
      await this.root.transport.communication.conversations.viewersForConversation(
        conversationId,
      )
    this.updateViewersPresence(conversationId, viewers)
  }

  /**
   * Tries to get a conversation from memory first, then the underlying storage and then
   * if not found, from the API.
   * @param id
   */
  getById = async (id: string): Promise<ConversationModel | null> => {
    const conversation: ConversationModel | null =
      this.collection.get(id, { skipStorage: true }) ??
      (await this.collection.performQuery((repo) => repo.get(id))) ??
      (await this.fetchConversation(id))

    return conversation
  }

  findAll = async (query: ConversationQuery): Promise<ConversationModel[]> => {
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.collection.performQuery((table) => table.find(query))
    return this.root.transport.communication.conversations
      .list(query)
      .then((res) => this.load(res.result))
  }

  findOne = async (query: ConversationQuery): Promise<ConversationModel | null> => {
    let conv: ConversationModel | null | undefined
    query.phoneNumber = query.phoneNumber.split(',').sort().join(',')

    const filter = (c: CodableConversation): boolean => {
      return (
        (query.canBeNew ? true : !c.isNew) &&
        !c.deletedAt &&
        c.phoneNumber === query.phoneNumber &&
        (!query.directNumberId || query.directNumberId === c.directNumberId) &&
        (!query.phoneNumberId || query.phoneNumberId === c.phoneNumberId)
      )
    }

    /**
     * Check memory
     */
    if ((conv = this.collection.list.find(filter))) {
      return conv
    }

    /**
     * Check database
     */
    if (
      (conv = await this.collection
        .performQuery((repo) => repo.find(query))
        .then((a) => a[0]))
    ) {
      return conv
    }

    /**
     * Ask the API
     */
    return this.root.transport.communication.activities
      .list(query)
      .then((res) => this.load([res.conversation], res.result))
      .then((conversations) =>
        conversations.length > 0 ? conversations[0] ?? null : null,
      )
      .catch((e) => {
        if (e instanceof NotFoundError) {
          return null
        }

        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
        log.error(e)
        return null
      })
  }

  findList = (params: ConversationsQuery) => {
    return this.collection.performQuery((repo) =>
      repo.find(params).then(async (conversations) => {
        // We want to load the last activity to display it in the conversation list
        // This should be blocking so that it shows up immediately
        await this.root.activity.loadByIds(
          conversations.map((c) => c.lastActivityId ?? ''),
        )

        // Contact loading should be non-blocking
        this.root.contact
          .loadByNumbers(conversations.flatMap((c) => (c.phoneNumber ?? '').split(',')))
          .catch(logError)

        return conversations
      }),
    )
  }

  fetchMore = (params: ConversationsQuery) => {
    const key = this.keyForQuery(params)
    const pageInfo = this.pageInfos[key]
    if (!this.hasMore(params)) {
      return Promise.resolve()
    }
    return this.root.transport.communication.conversations
      .list({ ...params, before: pageInfo?.startId, includeDeleted: false })
      .then(
        action((response) => {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.load(response.result)
          this.pageInfos[key] = response.pageInfo
        }),
      )
  }

  search = (params: ActivitySearchParams) => {
    return this.root.transport.communication.conversations.search(params)
  }

  snooze = (id: string, duration = 525949200) => {
    return this.root.transport.communication.conversations.snooze(id, duration)
  }

  unsnooze = (id: string) => {
    return this.root.transport.communication.conversations.unsnooze(id)
  }

  markAsRead = (id: string) => {
    return this.root.transport.communication.conversations.markAsRead(id)
  }

  markAsUnread = (id: string) => {
    return this.root.transport.communication.conversations.markAsUnread(id)
  }

  archive = async (id: string) => {
    const conversation = this.collection.get(id)
    if (!conversation) {
      return
    }

    if (!conversation.isNew) {
      await this.root.transport.communication.conversations.archive(id)
    }
    this.delete$.next([conversation])
    this.collection.delete(conversation)
  }

  create(conversation: ConversationModel) {
    if (conversation.phoneNumberId) {
      return this.root.transport.communication.conversations.create({
        phoneNumberId: conversation.phoneNumberId,
        to: conversation.participants.map((p) => p.phoneNumber).join(','),
        conversationId: conversation.id,
      })
    }
  }

  updateName(conversation: ConversationModel) {
    if (!conversation.name) {
      return
    }
    return this.root.transport.communication.conversations
      .updateName({
        name: conversation.name,
        conversationId: conversation.id,
      })
      .then(() => {
        this.root.analytics.inbox.conversationRenamed()
      })
  }

  delete(conversation: ConversationModel) {
    return this.root.transport.communication.conversations.delete({
      id: conversation.id,
    })
  }

  // Sends updates to the backend for the current user...
  participantStatus = async (
    conversationId: string,
    status: ConversationParticipantStatus,
  ) => {
    const userId = this.root.user.current?.id
    if (!userId) {
      return
    }

    const newPresenceDetails: PresenceDetails = { timestamp: Date.now(), status }

    this.ensurePresence(conversationId, userId, newPresenceDetails)

    // @ts-expect-error unchecked index access
    this.presence[conversationId][userId] = newPresenceDetails

    // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    return this.root.transport.communication.conversations.participantStatus(
      conversationId,
      status,
    )
  }

  upload(
    file: File,
    onProgress?: (progress: number, total: number) => void,
  ): Promise<string> {
    return this.root.transport.communication.upload(file, onProgress)
  }

  loadUnreadCounts() {
    this.unreadCountSubscription?.unsubscribe()
    this.unreadCountSubscription = this.worker.onEvent.subscribe((event) => {
      if (event.type === 'unread changed') {
        this.setUnreadCounts(event.unreadCounts)
      }
    })
  }

  tearDown() {
    this.disposeBag.dispose()
    this.unreadCountSubscription?.unsubscribe()
  }

  private keyForQuery(params: ConversationsQuery) {
    return Object.keys(params)
      .sort()
      .map((k) => `${k}=${params[k]}`)
      .join('&')
  }

  async load(
    conversations: CodableConversation[],
    activities: IActivity[] = [],
  ): Promise<ConversationModel[]> {
    const convos = await this.collection.load(conversations)
    await this.root.activity.collection.load([
      ...conversations.map((c) => c.lastActivity).filter(isNonNull),
      ...activities,
    ])
    return convos
  }

  private handleWebsocket() {
    return this.root.transport.onNotificationData.subscribe((msg) => {
      switch (msg.type) {
        case 'activity-update':
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.handleNewActivityUpdates(msg)
          this.handleConversationUpdate(msg)
          return
        case 'conversation-update':
          return this.handleConversationUpdate(msg)
        case 'participant-status':
          return this.handleParticipantStatus(msg)
      }
    })
  }

  private handleParticipantStatus(message: ParticipantStatusMessage) {
    const newStatus = message.status.name
    const newTimestamp = Date.parse(message.status.effectiveAt)

    const newPresenceDetails: PresenceDetails = {
      status: newStatus,
      timestamp: newTimestamp,
    }

    this.ensurePresence(message.conversationId, message.user.id, newPresenceDetails)

    if (newStatus !== 'exited') {
      // Update timestamp when user is active
      // @ts-expect-error unchecked index access
      this.presence[message.conversationId][message.user.id].timestamp = newTimestamp
    }
    // @ts-expect-error unchecked index access
    this.presence[message.conversationId][message.user.id].status = newStatus
  }

  private handleConversationUpdate(
    msg: ActivityUpdateMessage | ConversationUpdateMessage,
  ) {
    if (this.updateTimeout) {
      window.clearTimeout(this.updateTimeout)
    }
    if (msg.conversation?.deletedAt) {
      if (!this.batchedConversationDeletes.includes(msg.conversation.id)) {
        this.batchedConversationDeletes.push(msg.conversation.id)
      }
    } else {
      const alreadyBatchedUpdate = this.batchedConversationUpdates.findIndex(
        (c) => c.id === msg.conversation.id,
      )
      if (alreadyBatchedUpdate === -1) {
        this.batchedConversationUpdates.push(msg.conversation)
      } else {
        this.batchedConversationUpdates[alreadyBatchedUpdate] = {
          ...this.batchedConversationUpdates[alreadyBatchedUpdate],
          ...msg.conversation,
        } as ConversationModel
      }
      if (msg.activity) {
        const alreadyBatchedActivity = this.batchedActivityUpdates.findIndex(
          (a) => a.id === msg.activity?.id,
        )
        if (alreadyBatchedActivity === -1) {
          this.batchedActivityUpdates.push(msg.activity)
        } else {
          this.batchedActivityUpdates[alreadyBatchedActivity] = {
            ...this.batchedActivityUpdates[alreadyBatchedActivity],
            ...msg.activity,
          } as ActivityModel
        }
        if (
          msg.activity.type === 'message' &&
          msg.activity.createdAt === msg.activity.updatedAt &&
          msg.activity.direction === 'incoming'
        ) {
          this.root.analytics.inbox.messageReceived()
        }
      }
    }
    // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.updateTimeout = window.setTimeout(async () => {
      if (this.batchedConversationDeletes.length > 0) {
        const serializedConvos = this.batchedConversationDeletes
          .map((convoId) => this.collection.get(convoId)?.serialize())
          .filter(isNonNull)
        this.delete$.next(serializedConvos)
        this.collection.deleteBulk(this.batchedConversationDeletes)
      }

      const conversationInstances = await this.collection.load(
        this.batchedConversationUpdates,
      )
      await this.root.activity.collection.load(this.batchedActivityUpdates)
      this.change$.next(conversationInstances)
      this.batchedConversationDeletes = []
      this.batchedConversationUpdates = []
      this.batchedActivityUpdates = []
    }, 500)
  }

  private setUnreadCounts(unreadCounts: Record<string, number>) {
    this.unreadCounts = unreadCounts
  }

  private handleActivityStoreUpdates() {
    return this.root.activity.collection.observe((changes) => {
      if (changes.type === 'delete') {
        const deletesByConversationId: Record<string, string[]> = {}

        for (const activity of changes.objects) {
          const conversation = this.collection.get(activity.conversationId)

          if (!conversation) {
            continue
          }

          deletesByConversationId[conversation.id] ??= []
          // @ts-expect-error unchecked index access
          deletesByConversationId[conversation.id].push(activity.id)
        }

        for (const [conversationId, activityIds] of Object.entries(
          deletesByConversationId,
        )) {
          const conversation = this.collection.get(conversationId)

          if (!conversation) {
            continue
          }

          conversation.activities.deleteBulk(activityIds)
        }
      }
    })
  }

  private async handleNewActivityUpdates({
    activity,
    conversation,
  }: ActivityUpdateMessage) {
    // Only check for new activities, ignore updates
    const createdAt = new Date(activity.createdAt ?? 0).getTime()
    const updatedAt = new Date(activity.updatedAt ?? 0).getTime()

    // We're diff checking here because the backend is somehow creating activities with
    // updatedAt older than createdAt
    if (updatedAt < createdAt) {
      return
    }

    const conversationInstance = this.collection.get(conversation.id)

    if (!conversationInstance) {
      // No conversation here means we can skip updating the conversation
      return
    }

    await this.root.activity.collection.load([activity])
    const activityInstance = this.root.activity.collection.get(activity.id)

    if (!activityInstance) {
      logError('Activity not found after loading it from the store')
      return
    }

    // Update conversation
    await this.collection.load([conversation])
    conversationInstance.activities.put(activityInstance)
  }

  private updateViewersPresence(
    conversationId: string,
    viewers: Viewer[] | null | undefined,
  ) {
    if (!viewers) {
      return
    }

    viewers.forEach((viewer) => {
      const newPresenceDetails = {
        status: viewer.name,
        timestamp: dayjs(viewer.effectiveAt).valueOf(),
      }
      this.ensurePresence(conversationId, viewer.userId, newPresenceDetails)
    })
  }

  /**
   * @description Ensures that a presence object exists for conversationId > userId
   */
  private ensurePresence(
    conversationId: string,
    userId: string,
    newPresenceDetails: PresenceDetails,
  ) {
    if (!this.presence[conversationId]) {
      this.presence[conversationId] = {}
    }

    if (!this.presence[conversationId][userId]) {
      this.presence[conversationId][userId] = newPresenceDetails
    }
  }
}

export { ConversationModel as Conversation, ActivityModel }
