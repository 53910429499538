import { makeAutoObservable, observable, runInAction } from 'mobx'

import { DisposeBag } from '@src/lib/dispose'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { PhoneNumberModel, RawRingOrder } from './model'
import { RingOrderModel } from './model'
import makePersistable from './storage/makePersistable'
import type { RingOrderRepository } from './worker/repository/RingOrderRepository'
import { RING_ORDER_TABLE_NAME } from './worker/repository/RingOrderRepository'

export default class RingOrderStore {
  readonly collection: PersistedCollection<RingOrderModel, RingOrderRepository>

  // Map of ring orders that have been modified since they were fetched from the server.
  // This map stores the raw ring order before it was modified.
  private readonly ringOrdersBeforeModificationsById = new Map<string, RawRingOrder>()
  private readonly disposeBag = new DisposeBag()
  private readonly fetchedRingOrdersByPhoneNumber = new Set<string>()
  private readonly defaultGroupSize = 10

  maxDialAtOnce = 20

  constructor(private root: Service) {
    this.collection = new PersistedCollection({
      table: root.storage.table(RING_ORDER_TABLE_NAME),
      classConstructor: (json) => new RingOrderModel(json as RawRingOrder),
    })

    makeAutoObservable<this>(this, {
      collection: observable,
      maxDialAtOnce: observable,
    })

    makePersistable(this, 'RingOrderStore', {
      ringOrdersBeforeModificationsById: root.storage.async(),
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.load()
  }

  getById(id: string): RingOrderModel | null {
    return this.collection.get(id)
  }

  getByPhoneNumberId(phoneNumberId: string): RingOrderModel[] {
    const localRingOrders = this.collection.list.filter(
      (item) => item.phoneNumberId === phoneNumberId,
    )

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.fetchRingOrderFromRemoteIfNeeded(phoneNumberId, localRingOrders)

    return localRingOrders
  }

  private async fetchRingOrderFromRemoteIfNeeded(
    phoneNumberId: string,
    localRingOrders: RingOrderModel[],
  ) {
    // We want to fetch the remote ring order at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedRingOrdersByPhoneNumber.has(phoneNumberId)
    if (alreadyFetched) {
      return
    }

    const remoteRingOrders: RawRingOrder[] | undefined =
      await this.fetchByPhoneNumberId(phoneNumberId)
    this.fetchedRingOrdersByPhoneNumber.add(phoneNumberId)

    remoteRingOrders?.forEach((remoteRingOrder) => {
      runInAction(() => {
        const localRingOrder = localRingOrders.find(
          (ringOrder) => ringOrder.id === remoteRingOrder.id,
        )

        if (localRingOrder) {
          // If the local version has been modified but not saved, instead of replacing it with
          // the remote version, we update the pre-modified version with the latest remote data.
          if (this.ringOrdersBeforeModificationsById.has(localRingOrder.id)) {
            this.ringOrdersBeforeModificationsById.set(localRingOrder.id, remoteRingOrder)
            return
          }
          // If both local and remote exist, update local ring order with remote data, but only if it hasn't been modified
          localRingOrder.deserialize(remoteRingOrder)
          return
        }

        // If the ring order exists only on remote, add it to the local collection
        this.collection.put(new RingOrderModel(remoteRingOrder))
      })
    })

    localRingOrders.forEach((localRingOrder) => {
      if (
        !remoteRingOrders?.some(
          (remoteRingOrder) => remoteRingOrder.id === localRingOrder.id,
        )
      ) {
        // If the ring order exists only on local, delete it
        this.collection.delete(localRingOrder.id)
      }
    })
  }

  async update(ringOrder: RingOrderModel) {
    const response = await this.root.transport.voice.ringOrders.update(
      ringOrder.serialize(),
    )

    this.collection.put(new RingOrderModel(response.result))
    this.ringOrdersBeforeModificationsById.delete(ringOrder.id)

    return response
  }

  delete(id: string) {
    return this.root.transport.voice.ringOrders.delete(id)
  }

  async fetchMaxDialAtOnce() {
    this.maxDialAtOnce = (
      await this.root.transport.voice.ringOrders.maxDialAtOnce()
    ).maxDialAtOnce
  }

  private buildRingOrder(phoneNumber: PhoneNumberModel): RingOrderModel {
    return new RingOrderModel({
      type:
        phoneNumber.members.length <= this.defaultGroupSize ? 'ALL_AT_ONCE' : 'RANDOM',
      phoneNumberId: phoneNumber.id,
      duration: 30,
      groupSize:
        phoneNumber.members.length > 1
          ? Math.min(this.defaultGroupSize, Math.ceil(phoneNumber.members.length / 2))
          : 1,
      groups: [],
      updatedAt: new Date().toISOString(),
      createdAt: new Date().toISOString(),
      version: 1,
      isDefault: false,
      dialUserId: null,
    })
  }

  addRingOrderToCollection(phoneNumberId: string) {
    const phoneNumber = this.root.phoneNumber.collection.get(phoneNumberId)
    if (!phoneNumber) {
      throw new Error('Phone number not found')
    }

    const ringOrder = this.buildRingOrder(phoneNumber)

    this.collection.put(ringOrder)

    return ringOrder
  }

  private load() {
    return this.collection.performQuery((repo) => repo.all())
  }

  private async fetchByPhoneNumberId(phoneNumberId: string) {
    const response =
      await this.root.transport.voice.ringOrders.getAllByPhoneNumberId(phoneNumberId)

    if (!response) {
      return
    }

    const raw = response.result

    return raw
  }

  updateLocalRingOrder(ringOrder: RawRingOrder) {
    if (!this.ringOrdersBeforeModificationsById.has(ringOrder.id)) {
      const rawOriginalRingOrder = this.collection.get(ringOrder.id)?.serialize()

      if (!rawOriginalRingOrder) {
        return
      }

      this.ringOrdersBeforeModificationsById.set(
        rawOriginalRingOrder.id,
        rawOriginalRingOrder,
      )
    }

    this.collection.put(new RingOrderModel(ringOrder))
  }

  discardRingOrderChanges(ringOrderId: string) {
    const ringOrder = this.collection.get(ringOrderId)

    if (!ringOrder) {
      return
    }

    const rawRingOrder = this.ringOrdersBeforeModificationsById.get(ringOrderId)

    if (!rawRingOrder) {
      return
    }

    this.collection.put(new RingOrderModel(rawRingOrder))
    this.ringOrdersBeforeModificationsById.delete(ringOrderId)
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'ring-order-update': {
          const ringOrder = new RingOrderModel(data.ringOrder)
          this.collection.put(ringOrder)
          break
        }
        case 'ring-order-delete': {
          this.collection.delete(data.ringOrderId)
          break
        }
      }
    })
  }
}
