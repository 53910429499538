import { makeAutoObservable } from 'mobx'

import { formatted } from '@src/lib/phone-number'
import type { Identity, MemberModel } from '@src/service/model'
import type { ContactModel } from '@src/service/model/contact/ContactModel'
import type { ContactSuggestionModel } from '@src/service/model/contact/ContactSuggestionModel'
import type { IntegrationContactModel } from '@src/service/model/contact/IntegrationContactModel'
import type ParticipantStore from '@src/service/participant-store'

class ParticipantModel implements Identity {
  constructor(
    private participantStore: ParticipantStore,
    public phoneNumber: string,
  ) {
    makeAutoObservable(this, {})

    participantStore.loadByNumber(phoneNumber)
  }

  get id(): string {
    // TODO the type here is not correct, this can also be undefined
    // @ts-expect-error unchecked index access
    return this.member?.id || this.contact?.id || this.integrationContacts[0]?.id
  }

  get contacts(): ContactModel[] {
    return this.participantStore.getContacts(this.phoneNumber)
  }

  get contact(): ContactModel | null {
    // @ts-expect-error unchecked index access
    return this.contacts.length > 0 ? this.contacts[0] : null
  }

  get contactSuggestion(): ContactSuggestionModel | null {
    return this.participantStore.getContactSuggestion(this)
  }

  get integrationContacts(): IntegrationContactModel[] {
    return this.participantStore.getIntegrationContacts(this.phoneNumber)
  }

  get member(): MemberModel | null {
    return this.participantStore.getMember(this.phoneNumber)
  }

  get name(): string {
    return this.openPhoneNumber?.isShared
      ? this.openPhoneNumber.name
      : this.member?.name ||
          this.contacts.find((c) => c.name)?.name ||
          this.integrationContacts.find((c) => c.name)?.name ||
          this.contactSuggestion?.formattedName ||
          formatted(this.phoneNumber) ||
          ''
  }

  get shortName(): string {
    return this.openPhoneNumber?.isShared
      ? this.openPhoneNumber.shortName
      : this.member?.shortName ||
          this.contacts.find((c) => c.shortName)?.shortName ||
          this.integrationContacts.find((c) => c.shortName)?.shortName ||
          this.contactSuggestion?.formattedName ||
          formatted(this.phoneNumber) ||
          ''
  }

  get source(): string | undefined {
    return (
      this.integrationContacts.find((c) => c.source)?.source ||
      this.contacts.find((c) => c.source)?.source ||
      undefined
    )
  }

  get initials(): string {
    return (
      this.member?.initials ??
      this.contacts.find((c) => c.initials)?.initials ??
      this.integrationContacts.find((c) => c.initials)?.initials ??
      '-'
    )
  }

  get pictureUrl(): string | undefined {
    return (
      this.member?.pictureUrl ||
      this.contacts?.find((c) => c.pictureUrl)?.pictureUrl ||
      this.integrationContacts?.find((c) => c.pictureUrl)?.pictureUrl ||
      undefined
    )
  }

  get pictureSymbol(): string | undefined {
    return this.openPhoneNumber?.isShared ? this.openPhoneNumber?.symbol : undefined
  }

  get phones() {
    return (
      this.member?.phones ||
      this.contact?.phones ||
      this.integrationContacts?.[0]?.phones ||
      []
    )
  }

  get emailAddresses(): string[] {
    return this.member
      ? this.member.emailAddresses
      : this.contact
      ? this.contact.emails.map((i) => i.value as string)
      : this.integrationContacts
      ? this.integrationContacts?.[0]?.emails ?? []
      : []
  }

  get isAnonymous() {
    if (!this.id) {
      return true
    }
    return (
      (!this.member || this.member.isAnonymous) &&
      (!this.contact || this.contact.isAnonymous) &&
      (!this.integrationContacts?.[0] || this.integrationContacts[0].isAnonymous)
    )
  }

  get openPhoneNumber() {
    return this.participantStore.getOpenPhoneNumber(this.phoneNumber)
  }

  get sourceIconUrl() {
    if (this.contact) {
      return this.contact.sourceIconUrl
    }

    if (this.integrationContacts?.[0]) {
      return this.integrationContacts[0].sourceIconUrl
    }

    return null
  }
}

export const isParticipantModel = (a: unknown): a is ParticipantModel => {
  return a instanceof ParticipantModel
}

export default ParticipantModel
