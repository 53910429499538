import { makeAutoObservable, toJS } from 'mobx'

import { getInitials, getSingleFirstAndLastName } from '@src/lib'
import isTruthy from '@src/lib/isTruthy'
import objectId from '@src/lib/objectId'
import { formatted, toE164 } from '@src/lib/phone-number'
import type { Identity, IdentityPhone, Model } from '@src/service/model/base'

export interface IntegrationContact {
  id: string
  identifiers: string[] | null
  orgId: string | null
  firstName: string | null
  lastName: string | null
  company: string | null
  role: string | null
  phoneNumbers: string[] | null
  emails: string[] | null
  externalId: string | null
  source?: string
  url: string | null
}

export interface ExtraHubSpotData {
  company?: string
  jobtitle?: string
  mobilephone?: string
}

export interface ExtraSalesforceData {
  Account?: { Name?: string }
  Company?: string
  MobilePhone?: string | null
  HomePhone?: string | null
  OtherPhone?: string | null
  AssistantPhone?: string | null
  Title?: string
  accountName?: string
  attributes?: { type?: 'Contact' | 'Lead' }
  ownerName?: string
}

export type ExtraData = ExtraHubSpotData | ExtraSalesforceData

export interface EnrichedHubSpotData {
  associatedcompanyid?: { name?: string }
  hubspot_owner_id?: { firstName?: string; lastName?: string }
}

export type EnrichedData = EnrichedHubSpotData

export enum IntegrationContactType {
  Contact = 'contact',
  Lead = 'lead',
}

export class IntegrationContactModel implements IntegrationContact, Identity, Model {
  id: string = objectId()
  identifiers: string[] | null = null
  orgId: string | null = null
  firstName: string | null = null
  lastName: string | null = null
  company: string | null = null
  role: string | null = null
  phoneNumbers: string[] | null = null
  emails: string[] | null = null
  externalId: string | null = null
  source?: string
  url: string | null = null
  data?: ExtraData | null = null
  enrichment?: EnrichedData

  constructor() {
    makeAutoObservable(this, {})
  }

  get pictureUrl(): string | null {
    return null
  }

  get name(): string {
    if (!this.firstName && !this.lastName) {
      if (this.company) {
        return this.company
      }
      if (this.phoneNumbers && this.phoneNumbers.length > 0) {
        // @ts-expect-error unchecked index access
        return formatted(this.phoneNumbers[0])
      }
      if (this.emails && this.emails.length > 0) {
        // @ts-expect-error unchecked index access
        return this.emails[0]
      }
      return 'Unnamed'
    }
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.firstName, this.lastName].find(isTruthy) ?? ''
  }

  get initials(): string {
    if (this.firstName || this.lastName) {
      const singleFirstNameAndLastName = getSingleFirstAndLastName(
        this.firstName,
        this.lastName,
      )
      return getInitials(singleFirstNameAndLastName)
    }

    if (this.company) {
      return getInitials(this.company)
    }

    return ''
  }

  get phones(): IdentityPhone[] {
    return (
      this.phoneNumbers?.map((number) => ({
        id: null,
        name: null,
        symbol: null,
        number,
        isOffHours: null,
      })) ?? []
    )
  }

  get emailAddresses(): string[] {
    return this.emails ?? []
  }

  get isAnonymous() {
    return false
  }

  get mobilePhone(): string | null {
    if (this.source && isHubSpotData(this.data, this.source)) {
      return this.data.mobilephone ? toE164(this.data.mobilephone) : null
    } else if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.MobilePhone ? toE164(this.data.MobilePhone) : null
    }
    return null
  }

  get homePhone(): string | null {
    if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.HomePhone ? toE164(this.data.HomePhone) : null
    }
    return null
  }

  get otherPhone(): string | null {
    if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.OtherPhone ? toE164(this.data.OtherPhone) : null
    }
    return null
  }

  get assistantPhone(): string | null {
    if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.AssistantPhone ? toE164(this.data.AssistantPhone) : null
    }
    return null
  }

  get jobTitle(): string | null {
    if (this.source && isHubSpotData(this.data, this.source)) {
      return this.data.jobtitle ?? null
    } else if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.Title ?? null
    }
    return null
  }

  get companyName(): string | null {
    if (this.source && isHubSpotData(this.data, this.source)) {
      return this.data.company ?? null
    } else if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.Company ?? this.data.accountName ?? this.data.Account?.Name ?? null
    }
    return null
  }

  get associatedCompany(): string | null {
    if (this.source && isEnrichedHubSpotData(this.enrichment, this.source)) {
      return this.enrichment.associatedcompanyid?.name ?? null
    }
    return null
  }

  get contactOwner(): string | null {
    if (this.source && isEnrichedHubSpotData(this.enrichment, this.source)) {
      const name = [
        this.enrichment.hubspot_owner_id?.firstName,
        this.enrichment.hubspot_owner_id?.lastName,
      ]
        .filter(isTruthy)
        .join(' ')
      return name ? name : null
    } else if (this.source && isSalesforceData(this.data, this.source)) {
      return this.data.ownerName ?? null
    }
    return null
  }

  get contactType(): IntegrationContactType {
    if (
      this.source &&
      isSalesforceData(this.data, this.source) &&
      this.data.attributes?.type === 'Lead'
    ) {
      return IntegrationContactType.Lead
    }
    return IntegrationContactType.Contact
  }

  get sourceIconUrl() {
    // Legacy map of sources to icon URLs, these will go away once we remove IntegrationContacts!
    switch (this.source) {
      case 'hubspot':
        return 'https://files.openphone.co/a/i/hubspot-icon.svg'
      case 'salesforce':
        return 'https://files.openphone.co/a/i/salesforce-icon.svg'
      default:
        return null
    }
  }

  deserialize = (json: any) => {
    Object.assign(this, json)
    return this
  }

  serialize = () => {
    return {
      id: this.id,
      identifiers: toJS(this.identifiers),
      orgId: this.orgId,
      firstName: this.firstName,
      lastName: this.lastName,
      company: this.company,
      role: this.role,
      phoneNumbers: toJS(this.phoneNumbers),
      emails: toJS(this.emails),
      externalId: this.externalId,
      source: this.source,
      url: this.url,
      data: toJS(this.data),
      enrichment: toJS(this.enrichment),
    }
  }
}

export const isIntegrationContact = (a: any): a is IntegrationContactModel => {
  return a instanceof IntegrationContactModel
}

const isHubSpotData = (data: any, source: string): data is ExtraHubSpotData => {
  return !!data && source === 'hubspot'
}

const isSalesforceData = (data: any, source: string): data is ExtraSalesforceData => {
  return !!data && source === 'salesforce'
}

const isEnrichedHubSpotData = (
  data: any,
  source: string,
): data is EnrichedHubSpotData => {
  return !!data && source === 'hubspot'
}
