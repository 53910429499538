import { useDraggable } from '@dnd-kit/core'
import { action } from 'mobx'
import { observer } from 'mobx-react-lite'
import { useEffect, useId, useRef } from 'react'

import { useEditor } from '@src/app/contact/ItemEditor'
import Tag from '@src/app/contact/Tag'
import colorForOption from '@src/app/contact/colorForOption'
import { useAppStore } from '@src/app/context'
import { useOpenInboxOrDefault } from '@src/app/inbox/useOpenInbox'
import IconButton from '@src/component/IconButton'
import { formatDate } from '@src/lib'
import useAsyncCopyToClipboard from '@src/lib/hooks/useAsyncCopyToClipboard'
import isNonNull from '@src/lib/isNonNull'
import { logError } from '@src/lib/log'
import { formatted } from '@src/lib/phone-number'
import type { TemplateTagItemOption } from '@src/lib/search'
import { MemberModel } from '@src/service/model'
import type { ContactItemModel } from '@src/service/model/contact/ContactItemModel'
import type { ContactModel } from '@src/service/model/contact/ContactModel'
import Checkbox from '@ui/Checkbox'
import {
  CallIcon,
  CopyIcon,
  EnvelopeIcon,
  MessageIcon,
  NewWindowIcon,
} from '@ui/icons/tint/16/general'

import type { ContactItemMethod } from './Item'
import Item from './Item'
import * as styles from './custom-item.css'
import { isSafeContactUrl } from './isSafeContactUrl'
import { isValueChanged } from './isValueChanged'
import { placeholders } from './placeholders'

interface CustomItemProps {
  contact: ContactModel
  contactItem: ContactItemModel
  defaultName: string
  placeholder: string | null
  editingId: string | null
  isReadOnly?: boolean
}

const CustomItem = function ({
  contact,
  contactItem,
  defaultName,
  placeholder,
  editingId,
  isReadOnly = false,
}: CustomItemProps) {
  const id = useId()
  const itemRef = useRef<ContactItemMethod>(null)
  const valueRef = useRef<HTMLDivElement>(null)
  const contactItemRef = useRef<ContactItemModel>(contactItem)
  const [editor] = useEditor()
  const { inboxes, toast, prompt, service } = useAppStore()
  const { active } = useDraggable({ id })
  const templateItem = contactItem.template
  const inbox = useOpenInboxOrDefault()
  const copyToClipboard = useAsyncCopyToClipboard()

  const messagingEnabled =
    service.capabilities.features.messagingEnabled &&
    !(
      service.billing.getCurrentSubscription().frozenState ||
      service.billing.getCurrentSubscription().isReviewRejected
    )
  const callingEnabled =
    service.capabilities.features.callingEnabled &&
    !(
      service.billing.getCurrentSubscription().frozenState ||
      service.billing.getCurrentSubscription().isReviewRejected
    )

  const callingButtonTitle = (() => {
    if (callingEnabled) {
      return 'Call'
    }

    if (service.billing.getCurrentSubscription().frozenState) {
      return 'You cannot make calls while your workspace is frozen'
    }

    if (service.billing.getCurrentSubscription().isReviewRejected) {
      return 'You cannot make calls while your workspace is disabled'
    }

    return 'Your plan currently does not support this feature'
  })()

  const messagingButtonTitle = (() => {
    if (messagingEnabled) {
      return 'Message'
    }

    if (service.billing.getCurrentSubscription().frozenState) {
      return 'You cannot send messages while your workspace is frozen'
    }

    if (service.billing.getCurrentSubscription().isReviewRejected) {
      return 'You cannot send messages while your workspace is disabled'
    }

    return 'Your plan currently does not support this feature'
  })()

  useEffect(() => {
    // There's a bug that can happen if a contact is in the process of saving
    // when the user clicks to edit an item (ex: ENG-5425). When the user
    // clicks to edit, the handleSave function below, which has captured the
    // current value of contactItem, is passed into the contact editor. After
    // the save has finished, the updated contact is received via the websocket
    // and deserialized into the model. Deserialization creates new instances
    // of all of the ContactItem classes, so, the value captured by the
    // handleSave function is stale. When handleSave is called, the stale class
    // is updated and then contact.update() is called, which "sees" no changes
    // and the update is lost.
    contactItemRef.current = contactItem
  }, [contactItem])

  useEffect(() => {
    if (editingId === contactItem.id) {
      if (templateItem && !templateItem.name) {
        itemRef.current?.changeName()
      } else {
        handleEditValue()
      }
    }
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    // eslint-disable-next-line react-hooks/exhaustive-deps -- FIXME: Fix this ESLint violation!
  }, [])

  const handleSave = (value: any) => {
    // must use contactItemRef in here - see comment above
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    const newValue = typeof value === 'string' ? value.trim() : value
    if (isValueChanged(contactItemRef.current.value, newValue)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      contactItemRef.current.update({ value: newValue }).catch(toast.show)
    }
  }

  const handleSaveTag = (value: any, newOption?: TemplateTagItemOption) => {
    const tagExists = Array.isArray(templateItem?.options)
      ? templateItem?.options.some(
          (option: TemplateTagItemOption) => option.name === newOption?.name,
        )
      : undefined

    if (newOption && !tagExists) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      templateItem?.update({
        options: [
          ...(Array.isArray(templateItem.options)
            ? (templateItem.options as TemplateTagItemOption[])
            : []),
          newOption,
        ],
      })
    }
    handleSave(value)
  }

  const handleEditValue = () => {
    if (contactItem.type === 'phone-number') {
      if (valueRef.current) {
        editor(valueRef, {
          name: 'edit phone number',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          defaultValue: contactItem.value,
          onSave: handleSave,
        })
      }
    } else if (contactItem.type && Object.keys(placeholders).includes(contactItem.type)) {
      if (valueRef.current) {
        editor(valueRef, {
          name: 'edit string',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          defaultValue: contactItem.value,
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          placeholder: placeholders[contactItem.type],
          onSave: handleSave,
        })
      }
    } else if (contactItem.type === 'date') {
      if (valueRef.current) {
        editor(valueRef, {
          name: 'edit date',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
          defaultValue: isNonNull(contactItem.value) ? new Date(contactItem.value) : null,
          clear: Boolean(contactItem.value),
          onSave: (date) => {
            if (date) {
              handleSave(date.toISOString())
            } else {
              handleSave(null)
            }
          },
        })
      }
    } else if (contactItem.type === 'boolean') {
      contactItem.update({ value: !contactItem.value }).catch(toast.showError)
    } else if (contactItem.type === 'multi-select') {
      const isInvalidType =
        typeof contactItem.value !== 'string' &&
        !Array.isArray(contactItem.value) &&
        // nullish value is valid
        contactItem.value != null

      if (isInvalidType) {
        logError(
          new Error(
            `Contact ${contact.id} with user id: ${contact.userId} contains an invalid custom item (${contactItem.id})`,
          ),
        )

        return
      }

      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      const defaultValue =
        typeof contactItem.value === 'string'
          ? [contactItem.value]
          : contactItem.value ?? []

      if (valueRef.current && templateItem) {
        editor(valueRef, {
          name: 'edit tags',
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          defaultValue,
          templateItem,
          onSave: handleSaveTag,
        })
      }
    }
  }

  const handleCall = (event) => {
    if (inboxes.selectedPhoneNumber && typeof contactItem.value === 'string') {
      const isContactAMember = contact instanceof MemberModel

      service.voice
        .startCall(inboxes.selectedPhoneNumber, [
          {
            number: contactItem.value,
            userId: isContactAMember ? contact.id : null,
            type: isContactAMember
              ? 'member'
              : contact.local // in case of a number we create a "fake" local contact
              ? 'number'
              : 'contact',
          },
        ])
        .catch((error) => {
          toast.showError(error)
          logError(error)
        })
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    event.stopPropagation()
  }

  const handleMessage = (event: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
    inbox?.newConversation(contactItem.value)
    event.stopPropagation()
  }

  const handleNameChange = (name: string) => {
    if (contactItem.name != name) {
      contactItem.update({ name, isNew: false }).catch(toast.showError)
    }
    if (templateItem && templateItem.name != name) {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      templateItem.update({ name })
    }
  }

  const handleEmail = (event: React.MouseEvent) => {
    event.stopPropagation()
    window.open(`mailto:${contactItem.value}`)
  }

  const handleUrl = (event: React.MouseEvent) => {
    event.stopPropagation()

    if (!isSafeContactUrl(contactItem.value)) {
      toast.showError(`Cannot open URL with that protocol`)
      return
    }

    window.open(contactItem.value)
  }

  const handleCopy = (event: React.MouseEvent) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
    let val: string = contactItem.value
    if (contactItem.type === 'phone-number') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      val = formatted(contactItem.value)
    }
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    copyToClipboard({
      copyString: val,
      successMessage: 'Copied to clipboard.',
    })
    event.stopPropagation()
  }

  const handleDelete = () => {
    setTimeout(() => {
      prompt.show({
        title: 'Delete contact property',
        body: 'Deleting this contact property will remove it for your entire workspace and remove it from all contacts. Are you sure you want to continue?',
        actions: [
          {
            title: 'Delete',
            type: 'destructive',
            onClick: action(() => {
              if (templateItem) {
                templateItem.delete().catch(toast.showError)
              }

              contactItem.delete().catch(toast.showError)
            }),
          },
          {
            title: 'Cancel',
            type: 'secondary',
          },
        ],
      })
    })
  }

  const isBlocked =
    contactItem.type === 'phone-number' &&
    !!service.blocklist.byPhoneNumber[contactItem.value as string]

  const renderActions = () => {
    const actions: React.ReactNode[] = []
    if (contactItem.type === 'email') {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="email"
          title="Email"
          size={22}
          icon={<EnvelopeIcon />}
          onClick={handleEmail}
        />,
      )
    } else if (contactItem.type === 'url' && isSafeContactUrl(contactItem.value)) {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="link"
          size={22}
          title="Open in new window"
          icon={<NewWindowIcon />}
          onClick={handleUrl}
        />,
      )
    } else if (contactItem.type === 'phone-number' && !isBlocked) {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="call"
          size={22}
          title={callingButtonTitle}
          icon={<CallIcon />}
          onClick={handleCall}
          disabled={!callingEnabled}
        />,
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="message"
          size={22}
          title={messagingButtonTitle}
          icon={<MessageIcon />}
          onClick={handleMessage}
          disabled={!messagingEnabled}
        />,
      )
    }
    if (typeof contactItem.value === 'string') {
      actions.push(
        // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
        <IconButton
          key="copy"
          title="Copy"
          size={22}
          icon={<CopyIcon />}
          onClick={handleCopy}
        />,
      )
    }
    return actions.filter(isNonNull)
  }

  const name = contactItem.template?.name || contactItem.name || defaultName
  const isDraggable =
    contactItem.type !== 'phone-number' && contactItem.type !== 'email' && !isReadOnly

  const renderValue = () => {
    if (contactItem.type === 'phone-number') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return formatted(contactItem.value)
    } else if (contactItem.type && Object.keys(placeholders).includes(contactItem.type)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
      return contactItem.value
    } else if (contactItem.type === 'date') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
      return contactItem.value ? formatDate(contactItem.value) : null
    } else if (contactItem.type === 'boolean') {
      return (
        <Checkbox
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
          checked={contactItem.value}
          className={styles.checkbox}
          label={name}
        />
      )
    } else if (contactItem.type === 'multi-select') {
      return !contactItem.value ||
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation!
        contactItem.value.length === 0 ||
        !templateItem ? null : (
        <div className={styles.tags}>
          {/* eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- FIXME: Fix this ESLint violation! */}
          {contactItem.value.map ? (
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
            contactItem.value.map((item: string) => (
              <Tag key={item} name={item} color={colorForOption(item, templateItem)} />
            ))
          ) : (
            <Tag
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              key={contactItem.value}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
              name={contactItem.value}
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- FIXME: Fix this ESLint violation!
              color={colorForOption(contactItem.value, templateItem)}
            />
          )}
        </div>
      )
    }
  }

  const itemProps = isReadOnly
    ? ({
        disableName: true,
      } as const)
    : ({
        onNameChange: handleNameChange,
        onValueClick: handleEditValue,
        onDelete: handleDelete,
      } as const)

  return (
    <Item
      ref={itemRef}
      type={contactItem.type}
      name={name}
      draggable={isDraggable}
      isDragging={!!active}
      placeholder={placeholder ?? undefined}
      valueRef={valueRef}
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
      value={renderValue()}
      actions={renderActions()}
      {...itemProps}
    />
  )
}

export default observer(CustomItem)
