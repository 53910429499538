/* eslint-disable canonical/filename-match-exported -- FIXME: Fix this ESLint violation! */

import { action, flow, makeAutoObservable, remove } from 'mobx'
import type { Subscription } from 'rxjs'
import { Subject } from 'rxjs'

import type ById from '@src/lib/ById'
import StatefulPromise from '@src/lib/StatefulPromise'
import { chunk, insertAtIndex, removeAtIndex } from '@src/lib/collections'
import { logError } from '@src/lib/log'
import omitNullish from '@src/lib/omitNullish'
import PersistedCollection from '@src/service/collections/PersistedCollection'
import type { GroupMembership } from '@src/service/model'
import { buffer, MemberModel } from '@src/service/model'
import type { CodableCsvImportV2 } from '@src/service/model/CsvImportV2Model'
import CsvImportV2Model from '@src/service/model/CsvImportV2Model'
import type { CodableContact } from '@src/service/model/contact/ContactModel'
import { ContactModel } from '@src/service/model/contact/ContactModel'
import { ContactTemplateItemModel } from '@src/service/model/contact/ContactTemplateItemModel'
import type { CodableGoogleContactSettings } from '@src/service/model/contact/GoogleContactSettingsModel'
import { GoogleContactSettingsModel } from '@src/service/model/contact/GoogleContactSettingsModel'
import type { NoteModel } from '@src/service/model/contact/NoteModel'
import type { CodableSharedContactSettings } from '@src/service/model/contact/SharedContactSettingsModel'
import { SharedContactSettingsModel } from '@src/service/model/contact/SharedContactSettingsModel'
import type { CodableContactNoteReaction } from '@src/service/model/reactions/ContactNoteReactionModel'
import ContactNoteReactionModel, {
  isCodableContactNoteReaction,
} from '@src/service/model/reactions/ContactNoteReactionModel'
import makePersistable from '@src/service/storage/makePersistable'
import type ContactsClient from '@src/service/transport/contacts'
import type CsvImportV2Repository from '@src/service/worker/repository/CsvImportV2Repository'
import type { SharedContactSettingsRepository } from '@src/service/worker/repository/SharedContactSettingsRepository'

import type Service from '.'
import Collection from './collections/Collection'
import type { LegacyPageInfo } from './transport/lib/LegacyPaginated'
import type { GooglePeopleSyncProgressNotification } from './transport/websocket'
import type MainWorker from './worker/main'
import type {
  ContactGoogleSettingsRepository,
  ContactRepository,
  ContactTemplateItemRepository,
} from './worker/repository'

interface CsvImport {
  name: string
  userId: string
}

export default class ContactStore {
  private readonly collection: PersistedCollection<ContactModel, ContactRepository>
  readonly template: PersistedCollection<
    ContactTemplateItemModel,
    ContactTemplateItemRepository
  >
  readonly googleContactSettings: PersistedCollection<
    GoogleContactSettingsModel,
    ContactGoogleSettingsRepository
  >
  settings: PersistedCollection<
    SharedContactSettingsModel,
    SharedContactSettingsRepository
  >
  /**
   * @deprecated will be used only for legacy csv imports that were made locally
   */
  csvImports: CsvImport[] = []
  /**
   * Part of the new csv import flow.
   * It will hold all the information about the csv import jobs
   * Includes deleted entries
   */
  csvImportsV2: PersistedCollection<CsvImportV2Model, CsvImportV2Repository>
  /**
   * A collection derived from csvImportsV2.
   * It will hold only the active csv import jobs
   */
  activeCsvImportsV2 = new Collection<CsvImportV2Model>({
    filter: (item) => !item.deletedAt && item.status !== 'incomplete',
  })
  loaded = false
  isFetchingContacts = false

  private byNumber: ById<Map<ContactModel['id'], ContactModel>> = {}
  private contactNumbers: ById<string[]> = {}

  private prevIndexedDbCount: number | null = null
  private pageInfo: LegacyPageInfo | null = null
  private lastFetchedAt: number | null = null

  /**
   * Last contacts resync id received.
   *
   * This acts as a sequential ID that we use to determine if we need to resync
   * contacts from the server.
   */
  private lastContactsResyncId: string | null = null
  private contactUpdate$ = new Subject<ContactModel>()
  private contactTemplateItemUpdate$ = new Subject<ContactTemplateItemModel>()

  private fetchCsvPromise = new StatefulPromise(this.handleFetchCsv.bind(this))

  fetchContactsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['fetch']>>,
    Parameters<ContactsClient['fetch']>
  >
  contactSharingSettingsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['settings']['fetch']>>,
    Parameters<ContactsClient['settings']['fetch']>
  >
  googleContactSettingsPromise: StatefulPromise<
    Awaited<ReturnType<ContactsClient['settings']['fetchGoogleSettings']>>,
    Parameters<ContactsClient['settings']['fetchGoogleSettings']>
  >

  constructor(
    private root: Service,
    private worker: MainWorker,
  ) {
    this.collection = new PersistedCollection({
      table: this.root.storage.table('contact'),
      classConstructor: (json: CodableContact) => new ContactModel(root.contact, json),
    })
    this.template = new PersistedCollection({
      table: this.root.storage.table('contactTemplateItem'),
      classConstructor: () => new ContactTemplateItemModel(root.contact),
    })
    this.googleContactSettings = new PersistedCollection({
      table: this.root.storage.table('contactGoogleSettings'),
      classConstructor: () => new GoogleContactSettingsModel(this),
    })

    this.settings = new PersistedCollection({
      table: this.root.storage.table('contactSettings'),
      classConstructor: (json: CodableSharedContactSettings) =>
        new SharedContactSettingsModel(json),
    })

    this.csvImportsV2 = new PersistedCollection<CsvImportV2Model, CsvImportV2Repository>({
      table: this.root.storage.table('csvImports'),
      classConstructor: (json: CodableCsvImportV2) => new CsvImportV2Model(json),
    })

    this.activeCsvImportsV2.bind(this.csvImportsV2)

    makeAutoObservable(this, {})

    makePersistable<this, 'pageInfo' | 'lastFetchedAt' | 'lastContactsResyncId'>(
      this,
      'ContactStore',
      {
        pageInfo: root.storage.sync(),
        lastFetchedAt: root.storage.sync(),
        lastContactsResyncId: root.storage.sync(),
      },
    )

    this.fetchContactsPromise = new StatefulPromise(
      (query: Parameters<ContactsClient['fetch']>[0]) =>
        this.root.transport.contacts.fetch(query),
    )
    this.contactSharingSettingsPromise = new StatefulPromise(() =>
      this.root.transport.contacts.settings.fetch(),
    )
    this.googleContactSettingsPromise = new StatefulPromise(() =>
      this.root.transport.contacts.settings.fetchGoogleSettings(),
    )

    this.handleWebsocket()
    this.handleIndexByPhoneNumber()
    this.loadCsvImports()
  }

  /**
   * Don't use this getter, rely on asynchronous queries or other
   * ContactStore methods to achieve what you need.
   *
   * In case of doubts, please reach out to the team in #app-web.
   *
   * @deprecated
   */
  get deprecated_collection() {
    return this.collection
  }

  /**
   * Returns the total number of contacts.
   *
   * In the future, this count will be eventually consistent because
   * we'll only hold a subset of the contacts in the collection and
   * we'll either fetch the total count from the server or an asynchronous
   * query to the local database.
   */
  get totalContactCount() {
    return this.collection.length
  }

  get sharedContactSettings(): SharedContactSettingsModel | null {
    return this.settings.list[0] ?? null
  }

  set sharedContactSettings(value: Partial<SharedContactSettingsModel> | null) {
    const settings = this.settings.list[0]
    const defaultSharingIds = value?.defaultSharingIds

    if (settings && defaultSharingIds) {
      settings.localUpdate({ defaultSharingIds })
    }
  }

  get defaultSharingIds(): readonly string[] {
    if (!this.sharedContactSettings?.defaultSharingIds) {
      return []
    }

    return this.root.workspace.filterDeletedGroupIds(
      this.sharedContactSettings.defaultSharingIds,
    )
  }

  get sortedTemplates() {
    return this.template.list.sort((i1, i2) => {
      if (i1.order == null) {
        return 1
      }
      if (i2.order == null) {
        return -1
      }
      return i1.order - i2.order
    })
  }

  /**
   * Returns the 10 first contacts from the Contact collection
   *
   * In the future, this getter could return a list of suggested contacts
   * based on the user's recent activity or other criteria.
   */
  get suggestedContacts() {
    return this.collection.list.slice(0, 10)
  }

  onContactUpdate(callback: (contact: ContactModel) => void): Subscription {
    return this.contactUpdate$.subscribe(callback)
  }

  resyncGoogleContactSettings = (settings: CodableGoogleContactSettings) => {
    return this.root.transport.contacts.settings.resync(settings)
  }

  onTemplateItemUpdate(
    callback: (contact: ContactTemplateItemModel) => void,
  ): Subscription {
    return this.contactTemplateItemUpdate$.subscribe(callback)
  }

  get(id: string) {
    return this.collection.get(id)
  }

  getByNumber(number: string) {
    this.loadByNumber(number)
    return this.byNumber[number] ? Array.from(this.byNumber[number]?.values() ?? []) : []
  }

  getByNumberSorted(number: string) {
    // Without sorting it, the array could be "shuffled" by subsequent loads (for example the search reaction in `VoicePhoneNumberSelectorController`)
    // and return inconsistent results
    return this.getByNumber(number).sort(
      (a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0),
    )
  }

  loadAll = () => {
    return this.collection.performQuery((repo) => repo.all())
  }

  loadAllIfNecessary = () => {
    if (this.loaded) {
      return
    }
    this.loaded = true
    return this.loadAll()
  }

  loadByNumbers = async (numbers: string[]) => {
    const locallyMissingNumbers = numbers.filter((number) => !this.byNumber[number])

    if (!locallyMissingNumbers.length) {
      return []
    }

    const localLoadByNumbers = () => {
      return this.collection.performQuery((repo) =>
        repo.getByPhoneNumbers(locallyMissingNumbers),
      )
    }

    const apiLoadByNumbers = async () => {
      try {
        const paginatedResult =
          await this.root.transport.contacts.integration.get(locallyMissingNumbers)
        const models = await this.load(paginatedResult.result)

        return models ?? []
      } catch (error) {
        logError(new Error('Failed to load API contacts by identifiers'), {
          originalError: error,
        })
        return []
      }
    }

    const isWebApiContactsIntegrationEnabled =
      this.root.flags.flags.webApiContactsIntegration

    const [localContacts, integrationContacts] = await Promise.all([
      localLoadByNumbers(),
      isWebApiContactsIntegrationEnabled ? apiLoadByNumbers() : Promise.resolve([]),
    ])

    return [...localContacts, ...integrationContacts]
  }

  // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
  loadByNumber = buffer(this.loadByNumbers, { duration: 1000, maxSize: 100 })

  googleSync = (token: string, redirectUri = 'postmessage'): Promise<any> => {
    return this.root.transport.contacts.googleSync(token, redirectUri)
  }

  fetchMissing = async (): Promise<any> => {
    const self = this
    this.isFetchingContacts = true
    const shouldForceFetch = await this.getShouldForceFetch()

    if (shouldForceFetch || this.pageInfo?.hasNextPage !== false) {
      return this.fetchContactsPromise
        .run({
          limit: this.root.flags.flags.fetchContactsBatchLimit,
          lastId: shouldForceFetch ? undefined : this.pageInfo?.endId,
        })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp.result)
            self.pageInfo = resp.pageInfo
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
            self.isFetchingContacts = false
          }),
        )
        .then(() => this.fetchMissing())
    } else if (this.pageInfo?.hasNextPage === false && this.lastFetchedAt) {
      return this.fetchContactsPromise
        .run({
          since: new Date(this.lastFetchedAt),
          includeDeleted: true,
        })
        .then(
          flow(function* (resp) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment -- FIXME: Fix this ESLint violation!
            const contacts = yield self.load(resp)
            self.lastFetchedAt = Math.max(
              // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
              ...contacts.map((c) => c.updatedAt),
              Number(self.lastFetchedAt) + 1,
            )
            self.isFetchingContacts = false
          }),
        )
    }

    this.isFetchingContacts = false
  }

  async createBulk(contacts: ContactModel[]) {
    await this.collection.load(contacts)
    return Promise.all(
      chunk(contacts, 100).map((contacts) =>
        this.root.transport.contacts.createBulk(contacts.map((c) => c.toJSON())),
      ),
    )
  }

  update = (contact: ContactModel) => {
    // If there is a suggestion associated with the contact and the contact is local,
    // we want to accept the updated suggestion instead.
    const contactSuggestion = this.root.contactSuggestion.getByContact(contact)

    if (contactSuggestion && contact.local) {
      const suggestedFields = this.root.contactSuggestion.getMergedSuggestedFields(
        contact,
        contactSuggestion,
      )

      // We want to track updates made to "suggested" contacts
      this.root.analytics.workspace.aiContactSuggestionEdited(
        Object.keys(contact.serialize()),
        Object.keys(omitNullish(suggestedFields)),
      )

      return this.root.contactSuggestion.update(
        contactSuggestion,
        'accepted',
        suggestedFields,
      )
    }

    contact.localUpdate({ local: false })
    this.collection.put(contact)

    this.root.analytics.workspace.contactUpdated()

    return this.root.transport.contacts.update(contact.toJSON())
  }

  merge = (contact: ContactModel, withContacts: ContactModel[]) => {
    // TODO: offline support
    return this.root.transport.contacts.merge(
      contact.id,
      withContacts.map((c) => c.id),
    )
  }

  delete = async (contact: ContactModel) => {
    this.collection.delete(contact)
    if (!contact.local) {
      return this.root.transport.contacts.delete(contact.id)
    }
  }

  deleteBySource = async (source: string, sourceName: string) => {
    await this.loadAllIfNecessary()
    const ids = this.collection.list
      .filter((c) => c.source === source && c.sourceName === sourceName)
      .map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteLegacyCSVImportsByName = (name: string) => {
    this.csvImports = this.csvImports.filter((i) => i.name !== name)
  }

  deleteBySourceName = async (sourceName: string) => {
    await this.loadAllIfNecessary()
    const ids = this.collection.list
      .filter((c) => c.sourceName === sourceName)
      .map((c) => c.id)
    return this.collection.deleteBulk(ids)
  }

  /**
   * Deletes all contacts that were imported from the public API.
   *
   * We do this as a temporary measure to clean up API contacts on reload
   * because we don't have a mechanism to remove deleted contacts from the
   * public API.
   *
   * Called during app load, will timeout after 15 seconds to prevent users
   * from being stuck on a loading screen in very large workspaces (in terms of
   * contacts amount)
   */
  async deleteFromPublicApi() {
    try {
      const timeoutPromise = new Promise((resolve, reject) => {
        setTimeout(() => {
          reject(new Error('ContactStore.deleteFromPublicApi timeout'))
        }, 15000)
      })

      await Promise.race([
        this.collection
          .performQuery((repo) => repo.deleteFromPublicApi())
          .catch(logError),
        timeoutPromise,
      ])
    } catch (e) {
      logError(e)
    }
  }

  deleteAll = () => {
    const ids = this.collection.list.map((c) => c.id)
    return this.deleteBulk(ids)
  }

  deleteBulk = (ids: string[]) => {
    if (!ids || ids.length === 0) {
      return Promise.resolve()
    }
    this.collection.deleteBulk(ids)
    return Promise.all(
      chunk(ids, 500).map((ids) => this.root.transport.contacts.deleteBulk(ids)),
    )
  }

  shareBulk = async (
    contactIds: string[],
    shareIds: string[],
    progress: (progress: number) => void,
  ) => {
    progress(0)
    const batches = chunk(contactIds, 250)
    for (let i = 0; i < batches.length; i++) {
      const ids = batches[i]
      // @ts-expect-error unchecked index access
      await this.root.transport.contacts.shareBulk(ids, shareIds)
      progress((i + 1) / (batches.length - 1))
    }
  }

  putNote = (note: NoteModel) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.put(note.contact.id, note.toJSON())
  }

  deleteNote = (note: NoteModel) => {
    this.collection.put(note.contact)
    return this.root.transport.contacts.note.deleteNote(note.contact.id, note.id)
  }

  fetchTemplates = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.template.performQuery((repo) => repo.all())
    return this.root.transport.contacts.template
      .fetch()
      .then(action((res) => this.template.load(res, { deleteOthers: true })))
  }

  updateTemplate = (template: ContactTemplateItemModel) => {
    template.local = false
    this.template.put(template)
    return this.root.transport.contacts.template
      .put(template.serialize())
      .then(this.template.load)
  }

  deleteTemplate = async (template: ContactTemplateItemModel) => {
    this.template.delete(template)
    if (!template.local) {
      return this.root.transport.contacts.template.delete(template.serialize())
    }
  }

  reorderTemplates = (from: number, to: number) => {
    const sorted = [...this.sortedTemplates]
    const item = sorted[from]
    insertAtIndex(removeAtIndex(sorted, from), item, to).map((t, order) => {
      // @ts-expect-error unchecked index access
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      t.update({ order })
    })
  }

  fetchGoogleContactSettings = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.googleContactSettings.performQuery((repo) => repo.all())
    return this.googleContactSettingsPromise
      .run()
      .then((res) => this.googleContactSettings.load(res, { deleteOthers: true }))
  }

  fetchSettings = () => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.settings.performQuery((repo) => repo.all())
    return this.contactSharingSettingsPromise.run().then((res) => {
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.settings.load(res, { deleteOthers: true })
    })
  }

  saveLocalContact(contact: ContactModel) {
    this.root.analytics.workspace.contactCreated()
    this.collection.put(contact)
  }

  private handleFetchCsv() {
    return this.root.transport.contacts.csv.getAllImports()
  }

  get fetchCsvStatus() {
    return this.fetchCsvPromise.status
  }

  async loadCsvImportsV2() {
    if (this.fetchCsvStatus === 'idle') {
      const response = await this.fetchCsvPromise.run()
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      this.csvImportsV2.load(response)
    }
  }

  loadCsvImports() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.worker.service.contact.getUniqueSources('csv').then(
      action((data) => {
        this.csvImports = data.map((item) => ({
          name: item.sourceName,
          userId: item.userId,
        }))
      }),
    )
  }

  updateSettings = (
    settings: Pick<CodableSharedContactSettings, 'defaultSharingIds'>,
  ) => {
    return this.root.transport.contacts.settings.put(settings)
  }

  deleteSettings = (id: string) => {
    this.googleContactSettings.delete(id)
    return this.root.transport.contacts.settings.delete(id)
  }
  addNoteReaction(
    contactId: string,
    noteId: string,
    reaction: CodableContactNoteReaction,
  ) {
    return this.root.transport.contacts.note.addReaction(contactId, noteId, reaction)
  }

  deleteNoteReaction(
    contactId: string,
    noteId: string,
    reaction: CodableContactNoteReaction,
  ) {
    return this.root.transport.contacts.note.deleteReaction(
      contactId,
      noteId,
      reaction.id,
    )
  }

  /**
   * Calculates the total number of unique members that are contained
   * in the list of entity ids.
   *
   * @param entityIds string[] - List of user, organization, and group ids
   */
  getUniqueMemberCountInEntities(entityIds: string[]) {
    const org = this.root.organization.current
    if (org && entityIds.includes(org.id)) {
      return this.root.member.collection.length
    }

    const userMembersIds = entityIds.filter((id) => id.startsWith('US'))
    const groupAndPhoneNumberMembersIds = entityIds
      .filter((id) => id.startsWith('GR'))
      .reduce((result, groupId) => {
        const phoneNumberOrGroup =
          this.root.workspace.activeGroups.find((group) => group.id === groupId) ??
          this.root.phoneNumber.collection.list.find((ph) => ph.groupId === groupId)

        const membersIds: string[] =
          phoneNumberOrGroup?.members.map((member: MemberModel | GroupMembership) => {
            if (member instanceof MemberModel) {
              return member.id
            }

            return member.userId
          }) ?? []
        return [...result, ...membersIds]
      }, [] as string[])
    return [...new Set([...userMembersIds, ...groupAndPhoneNumberMembersIds])].length
  }

  submitCsvImportMetadata = (
    metadata: Parameters<typeof this.root.transport.contacts.csv.import>[0],
  ) => {
    return this.root.transport.contacts.csv.import(metadata)
  }

  updateCsvImportStatus = (
    id: Parameters<typeof this.root.transport.contacts.csv.importStatus>[0],
    jobMeta: Parameters<typeof this.root.transport.contacts.csv.importStatus>[1],
  ) => {
    return this.root.transport.contacts.csv.importStatus(id, jobMeta)
  }

  submitCsvFile = (file: File, url: string) => {
    return this.root.transport.contacts.csv.submitFile(file, url)
  }

  /**
   * Deletes a CSV Import entry from the collection,
   * as well as all the contacts associated with it.
   *
   * @param id string - csv import identifier
   */
  deleteCsvImport = (id: string) => {
    const csvImport = this.csvImportsV2.get(id)

    if (csvImport) {
      csvImport.deletedAt = new Date().toISOString()
      this.csvImportsV2.put(csvImport)
    }

    return this.root.transport.contacts.csv.deleteImport(id)
  }

  submitCsvImport = async (
    file: File,
    metadata: Parameters<typeof this.submitCsvImportMetadata>[0],
    customFields: ContactTemplateItemModel[],
  ) => {
    const [response] = await Promise.all([
      // submit csv job metadata
      await this.submitCsvImportMetadata(metadata),
      // Create template items if needed
      ...customFields.map((field) => this.updateTemplate(field)),
    ])

    if (!response || !file) {
      return
    }

    await this.submitCsvFile(file, response.s3SignedUrl)

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.updateCsvImportStatus(response.id, { status: 'uploaded', retries: 0 })
  }

  prepareResync(id: string) {
    if (this.lastContactsResyncId === null) {
      // First time we call this function will be after logging in
      // so we need to set the lastContactsResyncId to the first resync id we get
      this.lastContactsResyncId = id
      return
    }

    if (id === this.lastContactsResyncId || id < this.lastContactsResyncId) {
      return
    }

    this.lastFetchedAt = null
    this.pageInfo = null
    this.lastContactsResyncId = id
  }

  /**
   * Deletes all contacts from the collection and fetches them again.
   */
  forceReloadContacts() {
    // Set these to null to force a full fetch
    this.lastFetchedAt = null
    this.pageInfo = null

    this.collection.clear()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.fetchMissing()
  }

  private async getShouldForceFetch() {
    const contactTable: ContactRepository = this.root.storage.table('contact')
    const count = await contactTable.count()

    // A count of 0 means indexedDB is empty and we should attempt to
    // refetch all the data, but only if it wasn't previously 0 to
    // avoid an infinite loop of refetching
    const shouldForceFetch = this.prevIndexedDbCount !== 0 && count === 0
    this.prevIndexedDbCount = count

    return shouldForceFetch
  }

  private handleWebsocket() {
    this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'reaction-update':
          if (isCodableContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionUpdate(data.reaction)
          }
          break
        case 'reaction-delete':
          if (isCodableContactNoteReaction(data.reaction)) {
            return this.handleContactNoteReactionDelete(data.reaction)
          }
          break
        case 'contact-update':
        case 'contact-note-update':
        case 'contact-note-delete': {
          const isWebApiContactsIntegrationEnabled =
            this.root.flags.flags.webApiContactsIntegration

          if (
            data.contact.source === 'public-api' &&
            !isWebApiContactsIntegrationEnabled
          ) {
            // Skip API Contact-related notifications if the feature flag is disabled
            return
          }

          this.updateCsvImportSourcesIfNeeded(data.contact)

          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.collection.load(data.contact)
          const contact = this.collection.get(data.contact.id)
          if (contact) {
            this.contactUpdate$.next(contact)
          }
          break
        }
        case 'contact-delete':
          return this.collection.delete(data.contact.id)
        case 'bulk-operation-complete': {
          if (data.collection === 'contacts') {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
            this.fetchMissing()
          }
          return
        }
        case 'integrations-disconnected': {
          this.forceReloadContacts()
          return
        }
        case 'template-update': {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.template.load(data.template)
          const template = this.template.get(data.template.id)
          if (template) {
            this.contactTemplateItemUpdate$.next(template)
          }
          return
        }
        case 'template-delete':
          return this.template.delete(data.template.id)
        case 'google-people-sync-progress':
          return this.handleGooglePeopleSyncProgressNotification(data)
        case 'contact-settings-update':
          return this.handleContactSettingsUpdate(data.settings)
        case 'contact-settings-delete':
          return this.handleContactSettingsDelete(data.settings)
        case 'csv-import-processed':
          return this.handleCsvImportProcessed(data.data)
      }
    })
  }

  private handleCsvImportProcessed(value: CodableCsvImportV2) {
    this.csvImportsV2.put(new CsvImportV2Model(value))
  }

  private handleContactNoteReactionUpdate(value: CodableContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (note) {
      const reaction = note.reactions.find((reaction) => reaction.id === value.id)

      if (reaction) {
        reaction.deserialize(value)
      } else {
        note.reactions.push(new ContactNoteReactionModel(value))
      }
    }
  }

  private handleContactNoteReactionDelete(value: CodableContactNoteReaction) {
    const contact = this.collection.get(value.contactId)
    const note = contact?.notes.find((note) => note.id === value.noteId)

    if (!note) {
      return
    }

    const reaction = note.reactions.find((reaction) => reaction.id === value.id)
    if (reaction) {
      void note.deleteReaction(reaction)
    }
  }

  private updateCsvImportSourcesIfNeeded(contact: CodableContact) {
    if (
      contact.source === 'csv' &&
      contact.sourceName &&
      contact.userId &&
      !this.csvImports.some((source) => source.name === contact.sourceName)
    ) {
      this.csvImports.push({
        name: contact.sourceName,
        userId: contact.userId,
      })
    }
  }

  private handleContactSettingsUpdate(settings: CodableSharedContactSettings) {
    const item = this.settings.find((item) => item.id === settings.id)
    if (item) {
      item.deserialize(settings)
      this.settings.put(item)
    }
  }

  private handleContactSettingsDelete(settings: CodableSharedContactSettings) {
    this.settings.delete(settings.id)
  }

  private handleGooglePeopleSyncProgressNotification(
    msg: GooglePeopleSyncProgressNotification,
  ) {
    const settings = this.googleContactSettings.list.find(
      (s) => s.source === msg.status.source,
    )
    if (!settings) {
      return
    }
    settings.resyncStatus = msg.status.state
  }

  /**
   * As contacts are added/updated/deleted from the collection, this
   * function keeps map of phone numbers to contacts for fast lookup
   */
  private handleIndexByPhoneNumber() {
    this.collection.observe(
      action((event) => {
        if (event.type === 'put') {
          event.objects.forEach((contact) => {
            this.contactNumbers[contact.id] ??= []
            const numbers = this.contactNumbers[contact.id]
            // @ts-expect-error unchecked index access
            numbers.forEach((number) => {
              this.byNumber[number]?.delete(contact.id)
            })
            contact.phoneNumbers.forEach((item) => {
              const number = item.value

              this.byNumber[number] ??= new Map()

              if (!this.byNumber[number]?.has(contact.id)) {
                this.byNumber[number].set(contact.id, contact)
              }
            })

            this.contactNumbers[contact.id] = contact.phoneNumbers.map((i) => i.value)
          })
        } else if (event.type == 'delete') {
          event.objects.forEach((object) => {
            const numbers = this.contactNumbers[object.id]
            numbers?.forEach((number) => {
              this.byNumber[number]?.delete(object.id)
            })
            remove(this.contactNumbers, object.id)
          })
        }
      }),
    )
  }

  load(contacts: any) {
    if (!contacts) {
      return
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-return -- FIXME: Fix this ESLint violation!
    this.collection.deleteBulk(contacts.filter((c) => c.deletedAt))
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call -- FIXME: Fix this ESLint violation!
    return this.collection.load(contacts.filter((c) => !c.deletedAt))
  }
}
