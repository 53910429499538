import AddIcon from '@material-ui/icons/Add'
import cx from 'classnames'
import { observer } from 'mobx-react-lite'
import type { Key } from 'react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import EditTag from '@src/app/contact/ItemEditor/components/EditTag'
import { List, ListItem } from '@src/app/contact/ItemEditor/components/List'
import Tag from '@src/app/contact/Tag'
import colorForOption from '@src/app/contact/colorForOption'
import { useAppStore } from '@src/app/context'
import IconButton from '@src/component/IconButton'
import useInputState from '@src/lib/hooks/useInputState'
import useKeyStepper from '@src/lib/hooks/useKeyStepper'
import type { TemplateTagItemOption } from '@src/lib/search'
import { searchTags } from '@src/lib/search'
import type { ContactTemplateItemModel } from '@src/service/model/contact/ContactTemplateItemModel'
import Menu from '@ui/Menu'
import TextWrap from '@ui/TextWrap'
import Typography from '@ui/Typography'
import { DeleteIcon, MoreIcon, PencilIcon } from '@ui/icons/tint/20/general'

import * as styles from './TagList.css'

export interface TagListProps {
  values: string[]
  templateItem: ContactTemplateItemModel
  onNew: (value: string) => void
  onSelect: (value: string) => void
  onDelete: (value: string) => void
  onHide: () => void
  onSave: () => void
}

type CreatableTemplateTagItemOption = TemplateTagItemOption & { isNew?: true }
const notNewFirst = (
  a: CreatableTemplateTagItemOption,
  b: CreatableTemplateTagItemOption,
) => {
  if (a?.isNew) {
    return -1
  }
  if (b?.isNew) {
    return -1
  }
  return 1
}

const TagList = function ({
  values,
  templateItem,
  onNew,
  onSelect,
  onDelete,
  onHide,
  onSave,
}: TagListProps) {
  const { prompt } = useAppStore()
  const rootRef = useRef<HTMLDivElement>(null)
  const inputRef = useRef<HTMLInputElement>(null)
  const moreMenuButtonRef = useRef<HTMLButtonElement | null>(null)
  const [tagBeingEdited, setTagBeingEdited] = useState<TemplateTagItemOption | null>(null)
  const [input, setInput] = useInputState('')
  const [options, setOptions] = useState<CreatableTemplateTagItemOption[]>(
    Array.isArray(templateItem.options) ? templateItem.options : [],
  )

  const filteredOptions = useMemo(
    () => options.filter((item) => !values.includes(item.name)).sort(notNewFirst),
    [values, options],
  )

  useEffect(() => {
    const value = input.trim()
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    searchTags(
      value,
      Array.isArray(templateItem.options)
        ? (templateItem.options as TemplateTagItemOption[])
        : [],
    ).then((options) => {
      if (!value || options.find((opt) => opt.name === value)) {
        setOptions(options ?? [])
      } else {
        setOptions([{ isNew: true, name: value, color: '' }, ...options])
      }
    })
  }, [input, templateItem.options])

  const handleNewIfValid = (value: string) => {
    const newValue = value.trim()
    const options = templateItem.options as TemplateTagItemOption[]
    if (!options.some((option) => option.name === newValue)) {
      onNew(newValue)
      setInput('')
    }
  }

  const handleSelectIfValid = (value: string) => {
    if (!values.includes(value)) {
      onSelect(value)
      setInput('')
    }
  }

  // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
  const { selectedIndex, getItemProps } = useKeyStepper({
    items: filteredOptions,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- FIXME: ENG-6422 We should refactor useKeyStepper to accept a ref, not a DOM element
    // @ts-ignore
    // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
    node: rootRef.current,
    defaultSelectedIndex: -1,
    name: 'contact/editor/EditTags/TagsList',
    handleSelect: (item) => {
      if (item) {
        // If a menu item was picked, handle creating a new option
        // or selecting an existing option
        if (item.isNew) {
          handleNewIfValid(input)
        } else {
          handleSelectIfValid(item.name)
        }

        return
      }

      // If a menu item was not explicitly picked (i.e. "Enter" was pressed on the
      // input), see if there's an existing option based on the input and select it
      const existingItem = (templateItem.options as TemplateTagItemOption[]).find(
        (option) => option.name === input.trim(),
      )

      if (existingItem) {
        handleSelectIfValid(existingItem.name)
      } else {
        handleSave()
      }
    },
  })

  const handleSave = () => {
    const value = input.trim()
    if (value) {
      handleNewIfValid(value)
    } else {
      onSave()
      onHide()
    }
  }

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (
      event.key === 'Backspace' &&
      inputRef.current?.selectionStart === 0 &&
      values.length > 0
    ) {
      // @ts-expect-error unchecked index access
      onDelete(values[values.length - 1])
    }

    if (event.key === 'NumpadEnter' || event.key === 'Tab') {
      handleSave()
    }
  }

  const confirmTagDeletion = useCallback(
    (tag: TemplateTagItemOption) => {
      prompt.show({
        title: 'Delete tag',
        body: `
          Are you sure you want to delete this tag? This will also remove the tag from all contacts that currently use it. This action cannot be undone.
        `,
        actions: [
          {
            title: 'Delete tag',
            type: 'destructive',
            onClick: () => {
              templateItem.deleteOption(tag)
            },
          },
          { title: 'Cancel' },
        ],
      })
    },
    [prompt, templateItem],
  )

  const handleEditTag = (tag: TemplateTagItemOption, newName: string) => {
    templateItem.renameOption(tag, newName)
    setTagBeingEdited(null)
  }

  const handleStopEditingTag = () => {
    setTagBeingEdited(null)
    moreMenuButtonRef.current = null
  }

  const handleTagMoreMenuAction = useCallback(
    (tag: TemplateTagItemOption) => (key: Key) => {
      if (key === 'delete') {
        confirmTagDeletion(tag)
      }

      if (key === 'edit') {
        setTagBeingEdited(tag)
      }
    },
    [confirmTagDeletion],
  )

  return (
    <div ref={rootRef}>
      <div className={styles.root}>
        {values.map((value, index) => (
          <Tag
            key={`${value}${index}`}
            name={value}
            color={colorForOption(value, templateItem)}
            onDelete={() => onDelete(value)}
          />
        ))}
        <input
          autoFocus
          ref={inputRef}
          value={input}
          placeholder="Add a tag"
          onChange={setInput}
          onKeyDown={handleKeyDown}
          className={styles.input}
        />
      </div>
      {filteredOptions?.length > 0 ? (
        <List>
          {filteredOptions.map((item, index) => (
            <ListItem
              key={item?.name}
              className={styles.row}
              isSelected={selectedIndex === index}
              {...getItemProps(index)}
            >
              {item?.isNew ? (
                <>
                  <div className={cx(styles.color, styles.addNewIcon)}>
                    <AddIcon fontSize="inherit" color="inherit" />
                  </div>
                  <Typography
                    variant="footnote"
                    color="textPrimary"
                    className={styles.createButton}
                  >
                    Create <b>{input}</b>
                  </Typography>
                </>
              ) : (
                <>
                  <i
                    className={styles.color}
                    style={{ backgroundColor: colorForOption(item.name, templateItem) }}
                  />
                  <Typography variant="footnote" color="textPrimary">
                    <TextWrap value={item.name}>{item.name}</TextWrap>
                  </Typography>
                  <Menu.Provider>
                    <Menu.Trigger>
                      {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
                      <IconButton
                        size={24}
                        icon={<MoreIcon />}
                        className={styles.moreButton}
                        onClick={(event) => {
                          event.stopPropagation()
                          moreMenuButtonRef.current = event.target as HTMLButtonElement
                        }}
                      />
                    </Menu.Trigger>
                    <Menu.List onAction={handleTagMoreMenuAction(item)}>
                      <Menu.ListItem key="edit" textValue="Edit" icon={<PencilIcon />}>
                        Edit
                      </Menu.ListItem>
                      <Menu.ListItem
                        key="delete"
                        textValue="Delete"
                        icon={<DeleteIcon />}
                      >
                        Delete
                      </Menu.ListItem>
                    </Menu.List>
                  </Menu.Provider>
                </>
              )}
            </ListItem>
          ))}
        </List>
      ) : null}
      {tagBeingEdited !== null ? (
        <EditTag
          tag={tagBeingEdited}
          options={templateItem.options as TemplateTagItemOption[]}
          moreMenuButtonRef={moreMenuButtonRef}
          onSave={handleEditTag}
          onClose={handleStopEditingTag}
        />
      ) : null}
    </div>
  )
}

export default observer(TagList)
