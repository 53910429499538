/* eslint-disable react-refresh/only-export-components -- UXP-3725 - Fix files that don't work well with React Refresh */
import { observer } from 'mobx-react-lite'
import type { CSSProperties, HTMLAttributes } from 'react'
import { forwardRef } from 'react'

import { useAppStore } from '@src/app/context'
import type { Identity, PresenceModel } from '@src/service/model'
import {
  isMember,
  isParticipantModel,
  thumbnailUrl,
  isIdentity,
  isOrganizationModel,
} from '@src/service/model'
import Avatar from '@ui/Avatar'
import type { AvatarSize, AvatarProps } from '@ui/Avatar'
import { useTheme } from '@ui/theme'

import blockedImageUrl from './assets/blocked.svg?url'
import mutedImageUrl from './assets/muted.svg?url'
import onHoldImageUrl from './assets/on-hold.svg?url'

export type AvatarStatusBadge = keyof typeof badges

export const badges = {
  'on-hold': onHoldImageUrl,
  muted: mutedImageUrl,
  blocked: blockedImageUrl,
}

export interface AppAvatarBaseProps {
  style?: CSSProperties
  className?: string
  circleClassName?: string
  size?: AvatarSize
  identity?: Identity | Pick<Identity, 'pictureUrl' | 'pictureUrlDark'> | null
  /**
   * Override the badge of an avatar.
   *
   * If provided, this will override any badges an avatar may have, such as the
   * hubspot icon.
   */
  badge?: AvatarStatusBadge
  /**
   * Display a tooltip when hovering the avatar badge
   */
  badgeTooltip?: string
  hideStatus?: boolean
  hideBadge?: boolean
  'aria-describedby'?: string
}

/**
 * Events handlers accepted to integrate with other components like Tooltip
 */
export type AppAvatarEventHandlerProps = Pick<
  HTMLAttributes<HTMLDivElement>,
  'onFocus' | 'onBlur' | 'onPointerEnter' | 'onPointerLeave'
>

export type AppAvatarProps = AppAvatarBaseProps & AppAvatarEventHandlerProps

const AppAvatar = (
  {
    size,
    identity,
    badge: badgeProp,
    badgeTooltip,
    hideStatus,
    hideBadge,
    circleClassName,
    className,
    style,
    ...props
  }: AppAvatarProps,
  ref: React.ForwardedRef<HTMLDivElement>,
) => {
  const { service } = useAppStore()
  const { themeKey } = useTheme()

  const pictureUrl =
    (themeKey === 'dark' ? identity?.pictureUrlDark : identity?.pictureUrl) ??
    identity?.pictureUrl ??
    undefined

  const url: AvatarProps['url'] =
    pictureUrl &&
    thumbnailUrl(pictureUrl, {
      width: size,
      quality: 100,
    })
  const retinaUrl: AvatarProps['url'] =
    pictureUrl &&
    thumbnailUrl(pictureUrl, {
      width: size ? size * 2 : undefined,
      quality: 100,
    })
  let status: AvatarProps['status']
  let badge: AvatarProps['badge']
  let label: AvatarProps['label']
  let colorId: AvatarProps['colorId']
  let symbol: AvatarProps['symbol']
  let initials: AvatarProps['initials']
  let presence: PresenceModel | null = null
  const variant: AvatarProps['variant'] = isOrganizationModel(identity)
    ? 'workspace'
    : 'user'

  if (isParticipantModel(identity)) {
    presence = identity.member?.presence ?? null
  } else if (isMember(identity)) {
    presence = identity.presence
  }

  if (presence) {
    if (presence.onCall && service.capabilities.features.showOnCallStatusEnabled) {
      status = 'busy'
    } else if (presence.status === 'online') {
      status = presence.snoozed ? 'activeSnoozed' : 'active'
    } else {
      status = presence.snoozed ? 'inactiveSnoozed' : 'inactive'
    }
  }

  if (isIdentity(identity)) {
    symbol = identity.pictureSymbol
    initials = identity.initials || '-'
    colorId = identity.id
    label = `${identity.name}'s avatar`
    badge = identity.sourceIconUrl ?? undefined
  }

  if (hideStatus) {
    status = undefined
  }

  if (badgeProp) {
    badge = badges[badgeProp]
  }

  if (hideBadge) {
    badge = undefined
  }

  const avatarProps: AvatarProps = {
    status,
    badge,
    badgeTooltip,
    label,
    colorId,
    symbol,
    initials,
    url,
    retinaUrl,
    size,
    circleClassName,
    className,
    style,
    variant,
    ...props,
  }

  return <Avatar ref={ref} {...avatarProps} />
}

export default observer(forwardRef(AppAvatar))
