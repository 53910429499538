import type {
  AuthenticatedConnectUser,
  InstallIntegrationOptions,
  CompleteInstallOptions,
  ConnectParams,
  paragon,
} from '@useparagon/connect'
import { createContext } from 'react'

type ParagonIntegrationMetadataWithOverloads = ReturnType<
  typeof paragon.getIntegrationMetadata
>
type ArrayTypeIfArray<T> = T extends (infer U)[] ? U : never

export type ParagonIntegrationMetadata =
  ArrayTypeIfArray<ParagonIntegrationMetadataWithOverloads>

export interface ParagonUserSettings {
  [integration: string]: ParagonIntegrationUserSettings
}

export interface ParagonIntegrationUserSettings {
  phoneNumberSettings?: {
    [phoneNumberId: string]: {
      enabled: boolean
    }
  }
}

export interface ParagonContextValue {
  integrations: AuthenticatedConnectUser['integrations']
  getAllMetadata: () => ParagonIntegrationMetadata[]
  getMetadata: (integrationType: string) => ParagonIntegrationMetadata | null
  connect: (integrationType: string, options?: ConnectParams) => void
  migrate: (integrationType: string) => void
  install: (integrationType: string, options?: InstallIntegrationOptions) => Promise<void>
  completeInstall: (
    integrationType: string,
    options: CompleteInstallOptions,
  ) => Promise<void>
  migrateInstall: (
    integrationType: string,
    options?: InstallIntegrationOptions,
  ) => Promise<void>
  userSettings: ParagonUserSettings
  setUserSettings: (settings: ParagonUserSettings) => Promise<void>
  getUserToken: () => Promise<string>
}

export const ParagonContext = createContext<ParagonContextValue | null>(null)
