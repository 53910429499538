import { makeAutoObservable, observable } from 'mobx'

import {
  getInitials,
  getSingleFirstAndLastName,
  isArrayOfStrings,
  parseDate,
} from '@src/lib'
import isTruthy from '@src/lib/isTruthy'
import objectId from '@src/lib/objectId'
import { formatted, toE164 } from '@src/lib/phone-number'
import type ContactStore from '@src/service/contact-store'
import type { Identity, IdentityPhone, Model } from '@src/service/model/base'
import type { VisibilityType } from '@src/service/transport/contacts'

import type {
  CodableContactItem,
  ContactItemPhoneNumber,
  LocalCodableContactItem,
} from './ContactItemModel'
import { ContactItemModel } from './ContactItemModel'
import type { ContactTemplateItemModel } from './ContactTemplateItemModel'
import type { CodableNote } from './NoteModel'
import { NoteModel } from './NoteModel'

export type Source =
  | 'openphone'
  | 'device'
  | 'google-people'
  | 'csv'
  | 'other'
  | 'csv-v2'
  | 'public-api'

export interface CodableContact {
  clientId?: string | null
  company: string | null
  firstName: string | null
  fromPublicApi?: boolean
  id: string
  isEditable?: boolean
  items: CodableContactItem[]
  lastName: string | null
  meta?: Record<string, unknown>
  notes: CodableNote[]
  orgId: string
  location: string | null
  pictureUrl: string | null
  role: string | null
  sharedWith: string[]
  sortName?: string
  source?: Source
  sourceIconUrl?: string | null
  sourceName: string | null
  sourceUrl?: string | null
  userId: string | null
  createdAt: number | null
  deletedAt: number | null
  updatedAt: number | null
  _phoneNumbers?: string[]
  local: boolean | null
  visibility: VisibilityType | null
  pictureSymbol?: string | undefined
}

export class ContactModel implements Model, Identity {
  private raw: CodableContact

  constructor(
    private contactStore: ContactStore,
    attrs: Partial<CodableContact> = {},
  ) {
    this.raw = {
      clientId: null,
      company: null,
      createdAt: Date.now(),
      deletedAt: null,
      firstName: null,
      fromPublicApi: false,
      id: objectId(),
      isEditable: true,
      items: [],
      lastName: null,
      local: null,
      location: null,
      notes: [],
      orgId: '',
      pictureSymbol: undefined,
      pictureUrl: null,
      role: null,
      sharedWith: [],
      source: undefined,
      sourceIconUrl: null,
      sourceName: null,
      sourceUrl: null,
      updatedAt: Date.now(),
      userId: null,
      visibility: null,
    }

    this.deserialize(attrs)

    makeAutoObservable<this, 'raw'>(this, {
      raw: observable.deep,
    })
  }

  get id(): string {
    return this.raw.id
  }

  get visibility(): VisibilityType | null {
    return this.raw.visibility
  }

  get source(): Source | undefined {
    return this.raw.source
  }

  get sourceName(): string | null {
    return this.raw.sourceName
  }

  get sourceIconUrl(): string | null | undefined {
    return this.raw.sourceIconUrl
  }

  get sourceUrl(): string | null | undefined {
    return this.raw.sourceUrl
  }

  get userId(): string | null {
    return this.raw.userId
  }

  get firstName(): string | null {
    return this.raw.firstName
  }

  get lastName(): string | null {
    return this.raw.lastName
  }

  get company(): string | null {
    return this.raw.company
  }

  get role(): string | null {
    return this.raw.role
  }

  get location(): string | null {
    return this.raw.location
  }

  get pictureUrl(): string | null {
    return this.raw.pictureUrl
  }

  get pictureSymbol(): string | undefined {
    return this.raw.pictureSymbol
  }

  get items(): ContactItemModel[] {
    return this.raw.items as ContactItemModel[]
  }

  get sharedWith(): string[] {
    return this.raw.sharedWith
  }

  get deletedAt(): number | null {
    return this.raw.deletedAt
  }

  get createdAt(): number | null {
    return this.raw.createdAt
  }

  get updatedAt(): number | null {
    return this.raw.updatedAt
  }

  get local(): boolean | null {
    return this.raw.local
  }

  get notes(): NoteModel[] {
    return this.raw.notes as NoteModel[]
  }

  get orgId(): string {
    return this.raw.orgId
  }

  get clientId(): string | null | undefined {
    return this.raw.clientId
  }

  get isEditable(): boolean {
    return this.raw.isEditable ?? true
  }

  get fromPublicApi(): boolean {
    return this.raw.fromPublicApi ?? false
  }

  get name(): string {
    if (!this.firstName && !this.lastName) {
      if (this.company) {
        return this.company
      }
      if (this.phoneNumbers.length > 0) {
        return formatted(this.phoneNumbers[0]?.value ?? '')
      }
      if (this.emails.length > 0) {
        return String(this.emails[0]?.value ?? '')
      }
      return 'Unnamed'
    }
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get fullName(): string {
    return [this.firstName, this.lastName].filter(isTruthy).join(' ')
  }

  get shortName(): string {
    return [this.firstName, this.lastName].find(isTruthy) ?? ''
  }

  get initials(): string {
    if (this.firstName || this.lastName) {
      const singleFirstNameAndLastName = getSingleFirstAndLastName(
        this.firstName,
        this.lastName,
      )
      return getInitials(singleFirstNameAndLastName)
    } else if (this.company) {
      return getInitials(this.company)
    }
    return ''
  }

  get phoneNumbers() {
    return this.items.filter(
      (p): p is ContactItemPhoneNumber => p.type === 'phone-number' && !!p.value,
    )
  }

  get emails() {
    return this.items.filter((p) => p.type === 'email' && p.value)
  }

  get phones(): IdentityPhone[] {
    return this.phoneNumbers.map((p) => ({
      id: null,
      name: p.name,
      symbol: null,
      number: String(p.value),
      isOffHours: null,
    }))
  }

  get emailAddresses(): string[] {
    return this.emails.map((i) => String(i.value))
  }

  get job() {
    return [this.role, this.company].filter((x) => x).join(' at ')
  }

  get sortName() {
    return [
      this.firstName,
      this.lastName,
      this.company,
      ...this.phoneNumbers.map((i) => `~${i.value}`),
    ]
      .join('')
      .toLowerCase()
      .trim()
  }

  get isEmpty() {
    return (
      !this.firstName &&
      !this.lastName &&
      !this.company &&
      !this.role &&
      this.items.every((i) => i.isEmpty) &&
      this.notes.length === 0
    )
  }

  get totalMembersSharedWith() {
    return this.contactStore.getUniqueMemberCountInEntities(this.sharedWith)
  }

  get sortedNotes() {
    return (
      this.notes?.slice().sort((a, b) => (a.createdAt ?? 0) - (b.createdAt ?? 0)) ?? []
    )
  }

  get isAnonymous() {
    return this.local || !this.fullName
  }

  hasPhoneNumber(number: string) {
    return Boolean(
      this.items.find((i) => i.type === 'phone-number' && i.value === number),
    )
  }

  addItem(item: LocalCodableContactItem): ContactItemModel {
    const ci = new ContactItemModel(this.contactStore, this, item)
    this.items.push(ci)
    return ci
  }

  update(attrs: Partial<this> = {}) {
    this.localUpdate(attrs)

    return this.contactStore.update(this)
  }

  delete() {
    return this.contactStore.delete(this)
  }

  getOrCreateItemForTemplate(template: ContactTemplateItemModel) {
    let item = this.items.find((i) => i.templateKey === template.key && !i.deletedAt)
    if (!item) {
      item = new ContactItemModel(this.contactStore, this, {
        type: template.type,
        name: template.name,
        templateKey: template.key,
        isNew: true,
      })
      this.items.push(item)
    }
    return item
  }

  /**
   * Returns the list of tag values for the provided template id
   */
  getTagValues(templateId: string): string[] {
    return this.items
      .filter((item) => item.template?.id === templateId && !item.deletedAt)
      .flatMap((item) => (isArrayOfStrings(item.value) ? item.value : []))
  }

  deserialize(json: Partial<CodableContact>) {
    if (json) {
      const {
        sortName: _sortName,
        meta: _meta,
        notes,
        items,
        deletedAt,
        updatedAt,
        createdAt,
        _phoneNumbers,
        ...rest
      } = json

      Object.assign(this.raw, rest)

      this.raw.notes =
        notes
          ?.filter((note) => !note.deletedAt)
          .map((note) => {
            const existingNote = this.notes.find((item) => item.id === note.id)

            if (existingNote) {
              return existingNote.deserialize(note)
            } else {
              return new NoteModel(this.contactStore, this, note)
            }
          }) ?? []

      this.raw.items =
        items
          ?.filter((item) => !item.deletedAt)
          .map((item) => new ContactItemModel(this.contactStore, this, item)) ?? []

      if (updatedAt) {
        this.raw.updatedAt = parseDate(updatedAt)
      }

      if (createdAt) {
        this.raw.createdAt = parseDate(createdAt)
      }

      if (deletedAt) {
        this.raw.deletedAt = parseDate(deletedAt)
      }
    }
    return this
  }

  serialize(): CodableContact {
    return {
      _phoneNumbers: this.phoneNumbers.map((i) => toE164(i.value)).filter(isTruthy),
      clientId: this.clientId,
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      fromPublicApi: this.fromPublicApi,
      id: this.id,
      isEditable: this.isEditable,
      items: this.items.map((i) => i.serialize()),
      lastName: this.lastName,
      local: this.local,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      orgId: this.orgId,
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      sortName: this.sortName,
      source: this.source,
      sourceIconUrl: this.sourceIconUrl,
      sourceName: this.sourceName,
      sourceUrl: this.sourceUrl,
      updatedAt: this.updatedAt,
      userId: this.userId,
      visibility: this.visibility,
    }
  }

  toJSON(): CodableContact {
    return {
      clientId: this.clientId,
      company: this.company,
      createdAt: this.createdAt,
      deletedAt: this.deletedAt,
      firstName: this.firstName,
      fromPublicApi: this.fromPublicApi,
      id: this.id,
      isEditable: this.isEditable,
      items: this.items.filter((i) => !i.isNew || i.value).map((i) => i.serialize()),
      lastName: this.lastName,
      local: this.local,
      location: this.location,
      notes: this.notes.map((i) => i.serialize()),
      orgId: this.orgId,
      pictureUrl: this.pictureUrl,
      role: this.role,
      sharedWith: [...this.sharedWith],
      source: this.source,
      sourceIconUrl: this.sourceIconUrl,
      sourceName: this.sourceName,
      sourceUrl: this.sourceUrl,
      updatedAt: this.updatedAt,
      userId: this.userId,
      visibility: this.visibility,
    }
  }

  localUpdate(attrs: Partial<ContactModel>): this {
    this.raw = { ...this.raw, ...attrs }
    return this
  }

  tearDown() {
    this.raw.items = []
  }
}
