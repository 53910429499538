import type { RumInitConfiguration } from '@datadog/browser-rum'
import { datadogRum } from '@datadog/browser-rum'
import { makeAutoObservable, reaction } from 'mobx'

import config from '@src/config'
import type Service from '@src/service'
import type { MemberModel, OrganizationModel } from '@src/service/model'

/**
 * The user context sent to DataDog.
 *
 * Some fields are used by DataDog (e.g. `id` and `email`), the rest are custom
 * to provide context to the user that experienced this issue.
 */
export type EnrichedDataDogUser = {
  id: string
  email: string
  name: string
  role: string
  organizationId: string
  organizationPlan?: string
}

interface DataDogOptions {
  sessionSampleRate?: number
  sessionReplaySampleRate?: number
  traceSampleRate?: number
}

// Validate sample rate and only return values between 0 and 100
const validateSampleRate = (value: number): number => {
  if (value < 0 || !Number.isFinite(value)) {
    return 0
  }
  if (value > 100) {
    return 100
  }
  return value
}

interface FeatureFlagQueueItem {
  key: string
  value: unknown
}

class DataDogStore {
  private isInitialized = false

  private featureFlagQueue: FeatureFlagQueueItem[] = []

  constructor(private root: Service) {
    makeAutoObservable(this)

    reaction(
      () => this.root.flags.flags.webDataDog,
      (isEnabled) => {
        if (isEnabled) {
          this.initialize({
            sessionSampleRate: root.flags.flags.webDataDogSessionSampleRate,
            sessionReplaySampleRate: root.flags.flags.webDataDogSessionReplaySampleRate,
            traceSampleRate: root.flags.flags.webDataDogTraceSampleRate,
          })
        } else {
          this.teardown()
        }
      },
    )
  }

  initialize({
    sessionSampleRate = 0,
    sessionReplaySampleRate = 0,
    traceSampleRate = 0,
  }: DataDogOptions = {}) {
    if (this.isInitialized) {
      return
    }

    const rumConfig: RumInitConfiguration = {
      applicationId: config.DATADOG_APP_ID,
      clientToken: config.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: config.DATADOG_SERVICE,
      env: config.LOG_ENV,
      version: config.FULL_VERSION,
      sessionSampleRate: validateSampleRate(sessionSampleRate),
      sessionReplaySampleRate: validateSampleRate(sessionReplaySampleRate),
      defaultPrivacyLevel: import.meta.env.PROD ? 'mask-user-input' : 'allow',
      allowedTracingUrls: [
        config.AI_SERVICE_URL,
        config.AUTH_SERVICE_URL,
        config.ACCOUNT_SERVICE_URL,
        config.BILLING_SERVICE_URL,
        config.COMMUNICATION_SERVICE_URL,
        config.CONTACT_SERVICE_URL,
        config.INTEGRATION_SERVICE_URL,
        config.MESSAGING_SERVICE_URL,
        config.REPORT_SERVICE_URL,
        config.VOICE_SERVICE_URL,
        config.WORKFLOW_SERVICE_URL,
        config.SEARCH_SERVICE_URL,
        config.WEBSOCKET_SERVICE_URL,
        config.API_SERVICE_URL,
      ],
      traceSampleRate: validateSampleRate(traceSampleRate),
      traceContextInjection: 'sampled',
      trackResources: true,
      trackLongTasks: true,
      trackUserInteractions: true,
      compressIntakeRequests: true,
      enableExperimentalFeatures: ['feature_flags'],
      excludedActivityUrls: [
        /.*\.commandbar\.com/,
        /.*\.launchdarkly\.com/,
        /cdn\.dreamdata\.cloud/,
        /sdk\.twilio\.com/,
        /.*\.heapanalytics\.com/,
        /.*\.heap-api\.com/,
        /.*\.google\.com/,
        /.*\.googletagmanager\.com/,
        /.*\.survicate\.com/,
        /.*\.clearbit\.com/,
      ],
    }

    datadogRum.init(rumConfig)

    this.isInitialized = true
  }

  addFeatureFlagEvaluation(key: string, value: unknown) {
    if (!this.isInitialized) {
      return
    }
    // If DataDog is not initialized, queue the evaluation
    if (!datadogRum.getInternalContext()) {
      this.featureFlagQueue.push({ key, value })
      return
    }

    datadogRum.addFeatureFlagEvaluation(key, value)
  }

  processQueuedFlags() {
    while (this.featureFlagQueue.length > 0) {
      const flag = this.featureFlagQueue.shift()
      if (flag) {
        datadogRum.addFeatureFlagEvaluation(flag.key, flag.value)
      }
    }
  }

  setUser(
    user: Pick<Required<MemberModel>, 'id' | 'email' | 'name' | 'role'> | undefined,
    organization:
      | (Pick<Required<OrganizationModel>, 'id'> & { plan?: string })
      | undefined,
  ) {
    if (!user || !organization) {
      return
    }

    const datadogUser: EnrichedDataDogUser = {
      id: user.id,
      email: user.email,
      name: user.name,
      role: user.role,
      organizationId: organization.id,
      organizationPlan: organization.plan,
    }

    datadogRum.setUser(datadogUser)
  }

  teardown() {
    this.isInitialized = false
    // Process any remaining flags before teardown
    this.processQueuedFlags()
    // Clear the queue
    this.featureFlagQueue.length = 0
    // Stop the RUM session
    datadogRum.stopSession()
  }
}

export default DataDogStore
