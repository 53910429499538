import aiAgentDarkAvatar from '@ui/icons/sona/Sona_dark.svg?url'
import aiAgentLightAvatar from '@ui/icons/sona/Sona_light.svg?url'

const aiAgentConstants = {
  id: 'ai-agent',
  name: 'Sona',
  darkAvatar: aiAgentDarkAvatar,
  lightAvatar: aiAgentLightAvatar,
} as const

export default aiAgentConstants
