import { useHover, usePress } from '@react-aria/interactions'
import { mergeProps } from '@react-aria/utils'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'

import { useAppStore } from '@src/app/context'
import Button from '@src/component/ButtonV2'
import useAsyncCopyToClipboard from '@src/lib/hooks/useAsyncCopyToClipboard'
import Tooltip from '@ui/Tooltip'
import Typography from '@ui/Typography'
import VisuallyHidden from '@ui/VisuallyHidden'
import { RefreshIcon } from '@ui/icons/tint/20/general'
import { VStack } from '@ui/layout/Stack'

import * as styles from './ErrorFallback.css'

export interface ErrorFallbackProps {
  error: unknown
  title?: string
  description?: string
  showClearCacheAndReloadAction?: boolean
  showDebugInfo?: boolean
  onReload: () => void
}

export const ErrorFallback = ({
  error,
  title = 'Something went wrong',
  description = 'Our team has been notified. If the problem persists please contact support.',
  showClearCacheAndReloadAction = true,
  showDebugInfo = true,
  onReload,
}: ErrorFallbackProps) => {
  const timeoutRef = useRef<number>()
  const [copyLabel, setCopyLabel] = useState('Click to copy')
  const copyToClipboard = useAsyncCopyToClipboard()
  const app = useAppStore()

  useEffect(() => {
    app.update.checkForUpdate()
  }, [app.update])

  const message =
    typeof error === 'object' &&
    error !== null &&
    'message' in error &&
    typeof error.message === 'string'
      ? error.message
      : String(error)

  function onCopy() {
    const errorData =
      // Attempt to get the error stack from the error object
      typeof error === 'object' &&
      error !== null &&
      'stack' in error &&
      typeof error.stack === 'string'
        ? error.stack
        : // If we're unable to get the error stack from the error object, we'll try with the error message
          message

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    copyToClipboard({
      copyString: errorData,
      successMessage: 'Copied to clipboard!',
    })
    clearTimeout(timeoutRef.current)
    setCopyLabel('Copied!')
    timeoutRef.current = window.setTimeout(() => {
      setCopyLabel('Click to copy')
    }, 1000)
  }

  function onClearCacheAndReload() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    app.reset()
  }

  const version = (() => {
    const fullVersion = app.config.FULL_VERSION
    const splitted = fullVersion.split('-')

    // Format is [VERSION, OP_ENV, GIT_COMMIT_SHA]
    // Commit SHA might be missing during local development
    if (splitted.length === 3) {
      const [version, _, commit] = splitted
      return `${version} (${commit})`
    }

    if (splitted.length === 2) {
      const [version] = splitted
      return `${version} (local)`
    }

    // Fallback to the full version, but this should never happen
    return fullVersion
  })()

  const { pressProps } = usePress({
    onPress: onCopy,
  })
  const { hoverProps, isHovered } = useHover({})

  return (
    <VStack className={styles.root} gap={16}>
      <VStack gap={8}>
        <Typography variant="title3" color="textPrimary">
          {title}
        </Typography>

        <Typography variant="body" color="textSecondary">
          {description}
        </Typography>
      </VStack>

      <VStack gap={10}>
        {/* eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button */}
        <Button
          startIcon={<RefreshIcon />}
          color="primary"
          height={36}
          onClick={onReload}
        >
          Reload
        </Button>

        {showClearCacheAndReloadAction ? (
          // eslint-disable-next-line custom-rules/no-deprecated-buttons -- FIXME: https://linear.app/openphone/issue/UXP-4347/migrate-deprecated-buttons-to-ds-button
          <Button
            variant="text"
            color="textSecondary"
            height={36}
            onClick={onClearCacheAndReload}
          >
            Clear cache and reload
          </Button>
        ) : null}
      </VStack>

      {showDebugInfo ? (
        <div>
          <Tooltip title={copyLabel}>
            <div>
              {/* @ts-expect-error This error should go away once we can make Typography generic in React 19 (we'll remove the forwardRef!) */}
              <Typography
                variant="footnote"
                as="pre"
                color="textSecondary"
                className={classNames(styles.debugInfo, {
                  [styles.debugInfoHovered]: isHovered,
                })}
                {...mergeProps(pressProps, hoverProps)}
              >
                <VStack gap={6} alignItems="flex-start">
                  <span>Error: {message}</span>

                  <span>Version: {version}</span>
                </VStack>
              </Typography>
            </div>
          </Tooltip>

          <VisuallyHidden>
            <button onClick={onCopy}>{copyLabel}</button>
          </VisuallyHidden>
        </div>
      ) : null}
    </VStack>
  )
}

export default ErrorFallback
