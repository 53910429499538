import type { WorkflowSharedTypes } from '@openphone/dexie-database/types'
import { action, makeAutoObservable, observable, runInAction } from 'mobx'

import {
  getPhoneMenuDestinationByNodeData,
  isPhoneMenuConfigValue,
  isPhoneMenuDigit,
} from '@src/app/components/workflow/call-flow/utils'
import type { StepDefinitionId } from '@src/app/components/workflow/types'
import RingOrderFormSchema from '@src/app/settings/phone-number/call-flow/RingOrder/RingOrderFormSchema'
import { DisposeBag } from '@src/lib/dispose'
import isNonNull from '@src/lib/isNonNull'
import uuid from '@src/lib/uuid'

import type Service from '.'
import PersistedCollection from './collections/PersistedCollection'
import type { EntityPhoneNumberModel } from './model'
import type { RawWorkflowDefinition } from './model/workflow/WorkflowDefinitionModel'
import WorkflowDefinitionModel from './model/workflow/WorkflowDefinitionModel'
import type { RawWorkflowStepDefinition } from './model/workflow/WorkflowStepDefinitionModel'
import WorkflowStepDefinitionModel from './model/workflow/WorkflowStepDefinitionModel'
import type { RawWorkflowTriggerDefinition } from './model/workflow/WorkflowTriggerDefinitionModel'
import WorkflowTriggerDefinitionModel from './model/workflow/WorkflowTriggerDefinitionModel'
import makePersistable from './storage/makePersistable'
import type { Validation } from './transport/WorkflowClient'
import {
  isAiTriggerDefinitionId,
  type AiTriggerDefinitionId,
} from './transport/ai-client/idSchemas'
import {
  WORKFLOW_DEFINITION_TABLE_NAME,
  type WorkflowDefinitionRepository,
} from './worker/repository/WorkflowDefinitionRepository'
import type { WorkflowStepDefinitionRepository } from './worker/repository/WorkflowStepDefinitionRepository'
import { WORKFLOW_STEP_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowStepDefinitionRepository'
import type { WorkflowTriggerDefinitionRepository } from './worker/repository/WorkflowTriggerDefinitionRepository'
import { WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME } from './worker/repository/WorkflowTriggerDefinitionRepository'

export const generateStepId = () => `WS${uuid()}`.replace(/-/g, '')

export default class WorkflowStore {
  private readonly definitionsCollection: PersistedCollection<
    WorkflowDefinitionModel,
    WorkflowDefinitionRepository
  >
  private readonly stepDefinitionsCollection: PersistedCollection<
    WorkflowStepDefinitionModel,
    WorkflowStepDefinitionRepository
  >
  private readonly triggerDefinitionsCollection: PersistedCollection<
    WorkflowTriggerDefinitionModel,
    WorkflowTriggerDefinitionRepository
  >
  // We keep track of the entity ids for which we have fetched definitions
  private readonly fetchedDefinitionsEntityIds = new Set<string>()
  private readonly fetchedStepDefinitionIds = new Set<string>()
  private readonly fetchedTriggerDefinitionIds = new Set<string>()
  // Map of definitions that have been modified since they were fetched from the server.
  // This map stores the raw definition before it was modified.
  private readonly definitionsBeforeModificationsById = new Map<
    string,
    RawWorkflowDefinition
  >()
  private readonly historyById = new Map<
    string,
    {
      currentIndex: number | null
      history: RawWorkflowDefinition[]
    }
  >()

  private readonly disposeBag = new DisposeBag()

  constructor(private root: Service) {
    this.definitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowDefinition) =>
        new WorkflowDefinitionModel(json),
    })
    this.stepDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_STEP_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowStepDefinition) =>
        new WorkflowStepDefinitionModel(json),
    })
    this.triggerDefinitionsCollection = new PersistedCollection({
      table: root.storage.table(WORKFLOW_TRIGGER_DEFINITION_TABLE_NAME),
      classConstructor: (json: RawWorkflowTriggerDefinition) =>
        new WorkflowTriggerDefinitionModel(json),
    })

    makeAutoObservable<
      this,
      | 'definitionsCollection'
      | 'stepDefinitionsCollection'
      | 'triggerDefinitionsCollection'
    >(this, {
      definitionsCollection: observable,
      stepDefinitionsCollection: observable,
      triggerDefinitionsCollection: observable,
    })

    makePersistable(this, 'WorkflowStore', {
      definitionsBeforeModificationsById: root.storage.async(),
      historyById: root.storage.async(),
    })

    this.disposeBag.add(this.subscribeToWebSocket())
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.load()
  }

  beta = {
    optIn: async (phoneNumbers: EntityPhoneNumberModel[]) => {
      await this.root.transport.voice.workflow.optIn(
        phoneNumbers.map((phoneNumber) => phoneNumber.id),
      )
      phoneNumbers.forEach(
        action((phoneNumber) => {
          phoneNumber.settings.incomingCallWorkflowEnabled = true
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.root.organization.phoneNumber.update(phoneNumber)
        }),
      )
    },

    optOut: async (phoneNumbers: EntityPhoneNumberModel[]) => {
      await this.root.transport.voice.workflow.optOut(
        phoneNumbers.map((phoneNumber) => phoneNumber.id),
      )
      phoneNumbers.forEach(
        action((phoneNumber) => {
          phoneNumber.settings.incomingCallWorkflowEnabled = false
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.root.organization.phoneNumber.update(phoneNumber)
        }),
      )
    },
  }

  getDefinitionById(definitionId: string): WorkflowDefinitionModel | null {
    return this.definitionsCollection.get(definitionId)
  }

  getDefinitionBeforeModificationsById(
    definitionId: string,
  ): WorkflowDefinitionModel | null {
    const rawOriginalDefinition =
      this.definitionsBeforeModificationsById.get(definitionId)

    if (!rawOriginalDefinition) {
      return null
    }

    return new WorkflowDefinitionModel(rawOriginalDefinition)
  }

  getWorkflowStepById(
    workflowDefinitionId: string,
    stepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string] | null {
    const definition = this.getDefinitionById(workflowDefinitionId)

    const workflowStep = definition?.workflowSteps[stepId]

    return workflowStep ?? null
  }

  private getDefinitionsForEntityIds(entityIds: string[]): WorkflowDefinitionModel[] {
    const localDefinitionsForEntityIds = this.definitionsCollection.list.filter(
      (definition) => entityIds.includes(definition.entityId),
    )

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.fetchDefinitionsForEntityIdsIfNeeded(entityIds, localDefinitionsForEntityIds)

    return localDefinitionsForEntityIds
  }

  getDefinitionsForEntityId(entityId: string): WorkflowDefinitionModel[] {
    return this.getDefinitionsForEntityIds([entityId])
  }

  getIncomingCallDefinitionsForEntityId(entityId: string): WorkflowDefinitionModel[] {
    return this.getDefinitionsForEntityId(entityId).filter(
      (definition) => definition.isIncomingCallFlow,
    )
  }

  getAllRawRemoteWorkflowDefinitions(entityIds: string[]): RawWorkflowDefinition[] {
    const allDefinitions = this.getDefinitionsForEntityIds(entityIds)
    const remoteDefinitionsMap = this.definitionsBeforeModificationsById

    const additionalDefinitions = allDefinitions
      .filter((definition) => !remoteDefinitionsMap.has(definition.id))
      .map((definition) => definition.serialize())

    return [...remoteDefinitionsMap.values(), ...additionalDefinitions]
  }

  getEnabledIncomingCallDefinitionForEntityId(
    entityId: string,
  ): WorkflowDefinitionModel | null {
    return (
      this.getDefinitionsForEntityId(entityId).find(
        (definition) => definition.isIncomingCallFlow && definition.enabled,
      ) ?? null
    )
  }

  getStepDefinitionById(stepDefinitionId: string): WorkflowStepDefinitionModel | null {
    const localStepDefinition = this.stepDefinitionsCollection.get(stepDefinitionId)

    // TODO disabling this for now since it was called too frequently
    // this.fetchStepDefinitionFromRemoteIfNeeded(stepDefinitionId, localStepDefinition)

    return localStepDefinition ?? null
  }

  getTriggerDefinitionById(
    triggerDefinitionId: string,
  ): WorkflowTriggerDefinitionModel | null {
    const localTriggerDefinition =
      this.triggerDefinitionsCollection.get(triggerDefinitionId)

    // TODO disabling this for now since it was called too frequently
    // this.fetchTriggerDefinitionFromRemoteIfNeeded(
    //   triggerDefinitionId,
    //   localTriggerDefinition,
    // )

    return localTriggerDefinition ?? null
  }

  private updateLocalWorkflow(
    definition: WorkflowDefinitionModel,
    skipAddingToHistory = false,
  ) {
    if (!this.definitionsBeforeModificationsById.has(definition.id)) {
      const rawOriginalDefinition = this.definitionsCollection
        .get(definition.id)
        ?.serialize()

      if (!rawOriginalDefinition) {
        return
      }

      this.definitionsBeforeModificationsById.set(
        rawOriginalDefinition.id,
        rawOriginalDefinition,
      )
    }

    const augmentedDefinition = this.augmentDefinitionConfiguration(definition, false)

    this.definitionsCollection.put(augmentedDefinition)

    if (skipAddingToHistory) {
      return
    }

    this.addDefinitionToHistory(augmentedDefinition)
  }

  addDefinitionToHistory(definition: WorkflowDefinitionModel) {
    const currentHistory = this.historyById.get(definition.id)
    const rawOriginalDefinition = this.definitionsBeforeModificationsById.get(
      definition.id,
    )

    if (!rawOriginalDefinition) {
      return
    }

    this.historyById.set(
      definition.id,
      currentHistory
        ? {
            currentIndex: null,
            history: [
              ...currentHistory.history.slice(
                0,
                currentHistory.currentIndex ? currentHistory.currentIndex + 1 : undefined,
              ),
              definition.serialize(),
            ],
          }
        : {
            currentIndex: null,
            history: [rawOriginalDefinition, definition.serialize()],
          },
    )
  }

  canUndo(definitionId: string): boolean {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return false
    }

    const newIndex = this.getPreviousHistoryIndex(definitionId)

    if (newIndex === null) {
      return false
    }

    if (newIndex < 0) {
      return false
    }

    return true
  }

  canRedo(definitionId: string): boolean {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return false
    }

    const newIndex = this.getNextHistoryIndex(definitionId)

    if (newIndex === null) {
      return false
    }

    if (newIndex > currentHistory.history.length - 1) {
      return false
    }

    return true
  }

  private getPreviousHistoryIndex(definitionId: string): number | null {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return null
    }

    return (currentHistory.currentIndex ?? currentHistory.history.length - 1) - 1
  }

  private getNextHistoryIndex(definitionId: string): number | null {
    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return null
    }

    if (currentHistory.currentIndex === null) {
      return null
    }

    return currentHistory.currentIndex + 1
  }

  undo(definitionId: string) {
    if (!this.canUndo(definitionId)) {
      return
    }

    const newIndex = this.getPreviousHistoryIndex(definitionId)

    this.applyNewHistoryIndex(definitionId, newIndex)
  }

  redo(definitionId: string) {
    if (!this.canRedo(definitionId)) {
      return
    }

    const newIndex = this.getNextHistoryIndex(definitionId)

    this.applyNewHistoryIndex(definitionId, newIndex)
  }

  private applyNewHistoryIndex(definitionId: string, newIndex: number | null) {
    if (newIndex === null) {
      return
    }

    const currentHistory = this.historyById.get(definitionId)

    if (!currentHistory) {
      return
    }

    const rawDefinitionToRestore = currentHistory.history[newIndex]

    if (!rawDefinitionToRestore) {
      return
    }

    const definitionToRestore = new WorkflowDefinitionModel(rawDefinitionToRestore)

    this.updateLocalWorkflow(definitionToRestore, true)

    this.historyById.set(definitionId, {
      ...currentHistory,
      currentIndex: newIndex,
    })
  }

  private augmentDefinitionConfiguration(
    definition: WorkflowDefinitionModel,
    preventEmptyPhoneMenuDestinations,
  ) {
    const localDefinition = definition.serialize()

    Object.entries(localDefinition.workflowSteps).forEach(([stepId, step]) => {
      if (step.definitionId !== 'WSDphoneMenu') {
        return
      }

      const builtValue: WorkflowSharedTypes.PhoneMenuConfigValue = {}
      const value = step.configuration?.find(
        (config) => config.variableKey === 'WVphoneMenuOptions',
      )?.value

      step.branches?.forEach((branch) => {
        const isDefaultOption = branch.key === 'WBdefaultOption'
        const phoneMenuConfigKey = isDefaultOption
          ? 'optionDefault'
          : (branch.key.replace(
              'WB',
              '',
            ) as keyof WorkflowSharedTypes.PhoneMenuConfigValue)

        const target = localDefinition.workflowSteps[branch.nextStepId]

        if (!target) {
          return
        }

        const currentValue = isPhoneMenuConfigValue(value) ? value : {}

        const digit = isDefaultOption
          ? 'no-selection'
          : Number(branch.key.replace('WBoption', ''))

        if (!isPhoneMenuDigit(digit)) {
          return
        }

        const destination = getPhoneMenuDestinationByNodeData(
          target.definitionId,
          target.configuration ?? [],
          localDefinition.entityId,
        )

        if (!destination && preventEmptyPhoneMenuDestinations) {
          return
        }

        builtValue[phoneMenuConfigKey] = {
          digit,
          name: currentValue[phoneMenuConfigKey]?.name ?? undefined,
          phrase: currentValue[phoneMenuConfigKey]?.phrase ?? undefined,
          destination,
          stepDefinitionId: target.definitionId,
        }
      })

      step.configuration = [
        ...(step.configuration?.filter(
          (c) =>
            c.variableKey !== 'WVphoneMenuOptions' &&
            c.variableKey !== 'WVphoneMenuDefaultDestination',
        ) ?? []),
        {
          variableKey: 'WVphoneMenuOptions',
          value: builtValue,
        },
        {
          variableKey: 'WVphoneMenuDefaultDestination',
          value: builtValue.optionDefault?.destination ?? '',
        },
      ]

      // sort phone menu steps by key
      localDefinition.workflowSteps[stepId] = {
        ...step,
        branches: step.branches?.sort((a, b) => {
          // Sort branches by key so that the phone menu branches are always ordered by digit (since the digit is part of the key).
          // The default option should always be last.
          if (a.key === 'WBdefaultOption') {
            return 1
          }

          if (b.key === 'WBdefaultOption') {
            return -1
          }

          return a.key < b.key ? -1 : 1
        }),
      }
    })

    return new WorkflowDefinitionModel(localDefinition)
  }

  enableIncomingCallWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)

    // Do nothing if the definition is not found, if it's not an incoming call workflow,
    // if it's already enabled, or if it has pending changes
    if (
      !definition?.isIncomingCallFlow ||
      definition.enabled ||
      this.hasChanges(definitionId)
    ) {
      return
    }

    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      definition.entityId,
    )

    // Unlikely to happen, but guard against no phone number
    if (!phoneNumber) {
      return
    }

    const initialPhoneNumberForward = phoneNumber.forward

    // Get the currently enabled definition so we can optimisitcally disable it
    const enabledIncomingCallDefinition =
      this.getEnabledIncomingCallDefinitionForEntityId(definition.entityId)

    try {
      // If enabling the Forward All Calls workflow, update the phone number forward
      // setting to keep the mobile clients in sync
      if (definition.isForwardAllCallsIncomingCallFlow) {
        const forwardStep = Object.values(definition.workflowSteps).find(
          (step) =>
            step.definitionId === 'WSDforwardCall' ||
            step.definitionId === 'WSDforwardAllCalls',
        )

        const forwardConfigValue = forwardStep?.configuration?.find(
          (config) => config.variableKey === 'WVforwardeePhoneNumber',
        )?.value

        if (typeof forwardConfigValue === 'string') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          phoneNumber.update({ forward: forwardConfigValue })
        }
      } else if (phoneNumber.forward !== null) {
        // If enabling a definition other than the Forward All Calls workflow, clear
        // the phone number forward setting to keep the mobile clients in sync, but
        // only if it's not already null to prevent an unnecessary update
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        phoneNumber.update({ forward: null })
      }

      definition.deserialize({ ...definition.serialize(), enabled: true })
      enabledIncomingCallDefinition?.deserialize({
        ...enabledIncomingCallDefinition.serialize(),
        enabled: false,
      })

      // The web client simply needs to make this call to enable a workflow definition
      // and the backend will ensure that only one incoming call workflow is enabled
      // at a time for a given entityId (i.e. phoneNumberId).
      return this.root.transport.voice.workflow.enableDefinition(
        definition.entityId,
        definitionId,
      )
    } catch (error) {
      // Revert the changes to both the definitions if something goes wrong.
      // If in the odd chance an error occured but the definitions were still
      // updated on the backend, the client will receive websocket events with
      // the definition updates and the data will correct itself at that point.
      definition.deserialize({ ...definition.serialize(), enabled: false })
      enabledIncomingCallDefinition?.deserialize({
        ...enabledIncomingCallDefinition.serialize(),
        enabled: true,
      })

      // Ensure the phone number forward setting is reverted
      // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
      phoneNumber.update({ forward: initialPhoneNumberForward })

      throw error
    }
  }

  // Publish the Ai Agent trigger definitions
  async publishAiAgentTriggerDefinitions(definition: WorkflowDefinitionModel) {
    const triggerDefinitionIds = Object.values(definition.workflowSteps)
      .map((step) => {
        const triggerDefinitionId = step.configuration?.find(
          (config) => config.variableKey === 'WVagentTriggerDefinitionId',
        )?.value

        // Type guard to ensure it's a valid trigger definition ID
        if (isAiTriggerDefinitionId(triggerDefinitionId)) {
          return triggerDefinitionId
        }
        return null
      })
      .filter((id): id is AiTriggerDefinitionId => id !== null)

    // Update all Ai Agent trigger definitions attached to the workflow
    void Promise.all(
      triggerDefinitionIds.map(async (triggerId) => {
        const localTriggerDefinitions =
          await this.root.ai.automations.localTriggerDefinitions
        const localTriggerDefinition = localTriggerDefinitions.find(
          (t) => t.id === triggerId,
        )
        if (!localTriggerDefinition) {
          return
        }
        await this.root.ai.automations.updateTriggerDefinition({
          ...localTriggerDefinition,
        })
      }),
    )
  }

  async publishForwardAllCallsWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition?.isForwardAllCallsIncomingCallFlow) {
      return
    }

    // We need to store the definitionBeforeModifications object otherwise it will be overwritten by the
    // websocket 'workflow-definition-update' message
    const definitionBeforeModifications =
      this.definitionsBeforeModificationsById.get(definitionId)

    if (!definitionBeforeModifications) {
      return
    }

    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      definition.entityId,
    )
    const forwardStep = Object.values(definition.workflowSteps).find(
      (step) =>
        step.definitionId === 'WSDforwardCall' ||
        step.definitionId === 'WSDforwardAllCalls',
    )
    const forwardConfigValue = forwardStep?.configuration?.find(
      (config) => config.variableKey === 'WVforwardeePhoneNumber',
    )?.value

    if (!phoneNumber || typeof forwardConfigValue !== 'string') {
      return
    }

    const initialPhoneNumberForward = phoneNumber.forward

    // Clear the definitionsBeforeModificationsById entry so that the banner disappears
    this.definitionsBeforeModificationsById.delete(definitionId)

    try {
      const validation = await this.validateWorkflow(definitionId)

      if (!validation.valid) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
        throw validation
      }

      // If the definition is enabled, update the phone number forward setting to keep
      // the mobile clients in sync
      if (definition.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        phoneNumber.update({ forward: forwardConfigValue })
      }

      const response = await this.root.transport.workflow.definitions.put(
        definition.id,
        definition.serialize(),
      )

      // then store the updated definition in the collection (not necessary since it's already stored, but just in case)
      this.definitionsCollection.put(new WorkflowDefinitionModel(response))
    } catch (error) {
      // if there's an error, store the definitionBeforeModifications object back in the map
      this.definitionsBeforeModificationsById.set(
        definitionId,
        definitionBeforeModifications,
      )
      // and make sure that the phone number forward setting is reverted too, if it was changed
      if (definition.enabled) {
        // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
        phoneNumber.update({ forward: initialPhoneNumberForward })
      }

      throw error
    }
  }

  async publishWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)
    // We need to store the definitionBeforeModifications object otherwise it will be overwritten by the
    // websocket 'workflow-definition-update' message
    const definitionBeforeModifications =
      this.definitionsBeforeModificationsById.get(definitionId)

    if (!definition || !definitionBeforeModifications) {
      return
    }

    // Clear the definitionsBeforeModificationsById entry so that the banner disappears
    this.definitionsBeforeModificationsById.delete(definitionId)

    try {
      const validation = await this.validateWorkflow(definitionId)

      if (!validation.valid) {
        // eslint-disable-next-line @typescript-eslint/only-throw-error -- FIXME: Fix this ESLint violation!
        throw validation
      }

      // Publish the Ai Agent trigger definitions before publishing the workflow definition
      await this.publishAiAgentTriggerDefinitions(definition)

      // first create or update any ring orders in this definition
      await this.updateOrCreateRingOrders(definition)

      // then update the definition
      const response = await this.root.transport.workflow.definitions.put(
        definition.id,
        definition.serialize(),
      )

      // then store the updated definition in the collection (not necessary since it's already stored, but just in case)
      this.definitionsCollection.put(new WorkflowDefinitionModel(response))

      // Delete stale ai agent trigger definition
      this.root.ai.automations.deleteRemovedAiAgentTriggerDefinitionsFromRemote(
        definition,
        definitionBeforeModifications,
      )

      // then once all of that succeeds, start a non-blocking request to clean up the
      // deleted ring orders from the remote
      this.deleteRemovedRingOrdersFromRemote(definition, definitionBeforeModifications)
    } catch (error) {
      // if there's an error, store the definitionBeforeModifications object back in the map
      this.definitionsBeforeModificationsById.set(
        definitionId,
        definitionBeforeModifications,
      )

      throw error
    }
  }

  async validateWorkflow(definitionId: string): Promise<Validation> {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      // This should never happen, but let's throw a generic validation error if it does
      return {
        valid: false,
        errors: [
          {
            path: '',
            message: 'Definition not found',
          },
        ],
      }
    }

    // Validate the workfow against the backend. This way, workflowValidation is initialized to a
    // Validation object.
    const workflowValidation = await this.root.transport.workflow.definitions.validate(
      definitionId,
      this.augmentDefinitionConfiguration(definition, true).serialize(),
    )

    // Get all the ringOrderIds that need to be validated from the workflow steps of the definition
    const ringOrderIdsToValidate = Object.values(definition.workflowSteps)
      .map((step) => {
        if (step.definitionId !== 'WSDringUsers') {
          return null
        }

        const specificUserToDial = step.configuration?.find(
          (c) => c.variableKey === 'WVspecificUserToDial',
        )?.value

        // If the step is a ringUsers step with a specificUserToDial configuration, we don't need to validate the ring order
        // since we'll ignore it
        if (specificUserToDial) {
          return null
        }

        const ringOrderId = step.configuration?.find(
          (c) => c.variableKey === 'WVringOrderId',
        )?.value

        if (typeof ringOrderId !== 'string') {
          return null
        }

        return ringOrderId
      })
      .filter(isNonNull)

    // For each of them, check if they are valid against the form schema. We use a reduce function here to
    // modify the workflowValidation object that the backend returned.
    const ringOrderValidation: Validation = ringOrderIdsToValidate.reduce<Validation>(
      (validation, ringOrderId) => {
        const ringOrder = this.root.ringOrder.getById(ringOrderId)

        const parse = RingOrderFormSchema.safeParse(ringOrder)

        if (parse.success) {
          return validation
        }

        const workflowStepId = Object.values(definition.workflowSteps).find(
          (step) => step.configuration?.some((config) => config.value === ringOrderId),
        )?.id

        if (!workflowStepId) {
          return validation
        }

        validation.valid = false
        validation.errors.push({
          path: `workflowSteps/${workflowStepId}/configuration`,
          message: 'Ring order configuration not valid',
        })

        return validation
      },
      workflowValidation,
    )

    return ringOrderValidation
  }

  discardWorkflow(definitionId: string) {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      return
    }

    const rawDefinition = this.definitionsBeforeModificationsById.get(definitionId)

    if (!rawDefinition) {
      return
    }

    this.definitionsCollection.put(new WorkflowDefinitionModel(rawDefinition))
    this.definitionsBeforeModificationsById.delete(definitionId)
    this.historyById.delete(definitionId)

    this.root.ai.automations.discardLocalAiAgentTriggerDefinitions(
      definition.workflowSteps,
    )

    const discardRingOrderConfigChanges = (
      config: WorkflowSharedTypes.WorkflowConfigurationValue,
    ) => {
      if (config.variableKey === 'WVringOrderId' && typeof config.value === 'string') {
        this.root.ringOrder.discardRingOrderChanges(config.value)
      }
    }

    rawDefinition.configuration?.forEach((config) =>
      discardRingOrderConfigChanges(config),
    )
    Object.values(rawDefinition.workflowSteps).forEach((step) => {
      step.configuration?.forEach((config) => discardRingOrderConfigChanges(config))
    })
  }

  fetchStepDefinitions() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.root.transport.workflow.stepDefinitions.list(1000).then((response) => {
      this.stepDefinitionsCollection.putBulk(
        response.data.map((item) => new WorkflowStepDefinitionModel(item)),
      )
    })
  }

  fetchTriggerDefinitions() {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    this.root.transport.workflow.triggerDefinitions.list(1000).then((response) => {
      this.triggerDefinitionsCollection.putBulk(
        response.data.map((item) => new WorkflowTriggerDefinitionModel(item)),
      )
    })
  }

  addNode({
    definitionId,
    sourceId,
    targetId,
    stepDefinitionId,
    idToReUse,
    incomingBranchKey,
    configuration,
    goToTargetId,
  }: {
    definitionId: string
    sourceId: string
    targetId: string | null
    stepDefinitionId: StepDefinitionId
    idToReUse?: string
    incomingBranchKey?: string
    configuration?: WorkflowDefinitionModel['configuration']
    goToTargetId?: string | null
  }) {
    // TODO this is a placeholder implementation
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const stepDefinition = this.stepDefinitionsCollection.get(stepDefinitionId)

    if (!stepDefinition) {
      return
    }

    const id = idToReUse ?? generateStepId()

    const sourceStep = definition.workflowSteps[sourceId]

    // If the source is a step, we need to update the branch that points to the target.
    // If the source is a trigger, we don't need to do anything since there are no branches.
    // If we are providing an idToReUse, it means the branches already exist
    if (sourceStep && !idToReUse) {
      const branchToUpdateIndex =
        sourceStep.branches?.findIndex((branch) => branch.nextStepId === targetId) ?? -1
      const branchToUpdate = sourceStep.branches
        ? sourceStep.branches[branchToUpdateIndex]
        : null

      if (branchToUpdate) {
        branchToUpdate.key = incomingBranchKey ?? branchToUpdate.key
        branchToUpdate.nextStepId = id
      } else {
        const branchKey = incomingBranchKey
          ? incomingBranchKey
          : this.getStepDefinitionById(sourceStep.definitionId)?.outputBranches?.[0]?.key

        if (!branchKey) {
          return
        }

        sourceStep.branches = [
          ...(sourceStep.branches ?? []),
          {
            key: branchKey,
            nextStepId: id,
          },
        ]
      }
    }

    const newVoicemailStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id: generateStepId(),
      definitionId: 'WSDvoicemail',
      branches: [],
      configuration: this.getVoicemailConfiguration({
        phoneNumberId: definition.entityId,
        type:
          // If we're adding a business hours step, the new voicemail step will always be added in the after hours branch
          stepDefinitionId === 'WSDbusinessHours' ||
          // If the incomingBranchKey is WBafterHours, then we can be sure that the new voicemail step will be added
          // in the after hours branch
          incomingBranchKey === 'WBafterHours' ||
          // If none of the above is true, then we need to analyze the source step to determine if it's after business hours or not
          this.isStepAfterBusinessHours(definitionId, sourceId)
            ? 'afterHours'
            : 'duringHours',
      }),
    }

    const newVoicemailStepAfterRingUsers: WorkflowDefinitionModel['workflowSteps'][string] =
      {
        ...newVoicemailStep,
        id: generateStepId(),
        configuration: this.getVoicemailConfiguration({
          phoneNumberId: definition.entityId,
          // This step only gets added as a branch of the ringUsers step that gets added in the during hours branch of a new business hours step,
          // so we can be sure that it's always during hours
          type: 'duringHours',
        }),
      }

    const newRingUsersStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id: generateStepId(),
      definitionId: 'WSDringUsers',
      branches:
        stepDefinitionId === 'WSDbusinessHours'
          ? [
              {
                key: 'WBcallMissed',
                nextStepId: newVoicemailStepAfterRingUsers.id,
              },
            ]
          : [],
      configuration: this.getConfiguration(
        'WSDringUsers',
        definition.entityId,
        sourceId,
        definitionId,
      ),
    }

    const newGoToStep: WorkflowDefinitionModel['workflowSteps'][string] | null =
      goToTargetId
        ? {
            id: generateStepId(),
            definitionId: 'WSDgoTo',
            branches: [
              {
                key: 'WBgoTo',
                nextStepId: goToTargetId,
              },
            ],
          }
        : null

    const newStep: WorkflowDefinitionModel['workflowSteps'][string] = {
      id,
      definitionId: stepDefinitionId,
      configuration: configuration
        ? configuration
        : incomingBranchKey === 'WBduringHours' && stepDefinitionId === 'WSDvoicemail'
        ? this.getVoicemailConfiguration({
            phoneNumberId: definition.entityId,
            type: 'duringHours',
          })
        : incomingBranchKey === 'WBafterHours' && stepDefinitionId === 'WSDvoicemail'
        ? this.getVoicemailConfiguration({
            phoneNumberId: definition.entityId,
            type: 'afterHours',
          })
        : this.getConfiguration(
            stepDefinitionId,
            definition.entityId,
            sourceId,
            definitionId,
          ),
      branches:
        stepDefinitionId === 'WSDbusinessHours'
          ? [
              {
                key: 'WBduringHours',
                nextStepId: targetId ?? newRingUsersStep.id,
              },
              {
                key: 'WBafterHours',
                nextStepId: newVoicemailStep.id,
              },
            ]
          : stepDefinitionId === 'WSDphoneMenu'
          ? [
              ...(targetId
                ? [
                    {
                      key: 'WBoption1',
                      nextStepId: targetId,
                    },
                  ]
                : []),
              {
                key: 'WBdefaultOption',
                nextStepId: newVoicemailStep.id,
              },
            ]
          : stepDefinitionId === 'WSDplayAudioAndContinue' && newGoToStep
          ? [{ key: 'WBcontinue', nextStepId: newGoToStep.id }]
          : stepDefinitionId === 'WSDringUsers'
          ? [
              {
                key: 'WBcallMissed',
                nextStepId: targetId ?? newVoicemailStep.id,
              },
            ]
          : !targetId
          ? []
          : stepDefinitionId === 'WSDgoTo'
          ? [
              {
                key: 'WBgoTo',
                nextStepId: targetId,
              },
            ]
          : stepDefinition.outputBranches?.[0]?.key
          ? [
              {
                key: stepDefinition.outputBranches[0].key,
                nextStepId: targetId,
              },
            ]
          : // This should never happen since outputBranches should always have at least one item
            [],
    }

    const workflowSteps = {
      ...definition.workflowSteps,
      ...(sourceStep ? { [sourceStep.id]: sourceStep } : {}),
      [newStep.id]: newStep,
      ...(stepDefinitionId === 'WSDbusinessHours' ||
      stepDefinitionId === 'WSDphoneMenu' ||
      (stepDefinitionId === 'WSDringUsers' && !targetId)
        ? { [newVoicemailStep.id]: newVoicemailStep }
        : {}),
      ...(stepDefinitionId === 'WSDbusinessHours' && !targetId
        ? {
            [newRingUsersStep.id]: newRingUsersStep,
            [newVoicemailStepAfterRingUsers.id]: newVoicemailStepAfterRingUsers,
          }
        : {}),
      ...(stepDefinitionId === 'WSDplayAudioAndContinue' && newGoToStep
        ? { [newGoToStep.id]: newGoToStep }
        : {}),
    }

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      // An undefined sourceStep means this new node was placed
      // immediately after the trigger and is now technically the
      // first step in the definition (because triggers aren't part
      // of definition steps). In this case, update the definition
      // initialStepId to this new step's id.
      // Otherwise, preserve the original value.
      initialStepId: sourceStep ? definition.initialStepId : newStep.id,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition)

    return newStep.id
  }

  removeNode(
    definitionId: string,
    nodeId: string,
    removeBranchesPointingToNode = true,
    skipAddingToHistory = false,
  ) {
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const workflowSteps = { ...definition.workflowSteps }

    // @ts-expect-error unchecked index access
    if (workflowSteps[nodeId].definitionId !== 'WSDgoTo') {
      // @ts-expect-error unchecked index access
      this.getAllChildrenSteps(definition.id, workflowSteps[nodeId]).forEach(
        (step) => delete workflowSteps[step.id],
      )
    }

    delete workflowSteps[nodeId]

    if (removeBranchesPointingToNode) {
      Object.values(workflowSteps).forEach((step) => {
        step.branches = step.branches?.filter((branch) => branch.nextStepId !== nodeId)
      })
    }

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition, skipAddingToHistory)
  }

  replaceNode(
    definitionId: string,
    stepId: string,
    newStepDefinitionId: StepDefinitionId,
  ) {
    const source = this.getSources(definitionId, stepId).filter(
      (source) => source.definitionId !== 'WSDgoTo',
    )[0]

    const incomingBranchKey = source?.branches?.find(
      (branch) => branch.nextStepId === stepId,
    )?.key

    this.removeNode(definitionId, stepId, false, true)
    this.addNode({
      idToReUse: stepId,
      definitionId,
      // @ts-expect-error unchecked index access
      sourceId: source?.id,
      // TODO(WFA-431): this will change in the future when the user can freely add goTo blocks.
      // For now, we hardcode the destination since they are only used in the phone menu.
      // @ts-expect-error unchecked index access
      targetId: newStepDefinitionId === 'WSDgoTo' ? source.id : null,
      stepDefinitionId: newStepDefinitionId,
      incomingBranchKey,
      goToTargetId:
        // TODO(WFA-432): this will change in the future. For now, we hardcode the goToTargetId since
        // playAudioAndContinue blocks can only be added from the phone menu and have a custom behavior.
        newStepDefinitionId === 'WSDplayAudioAndContinue'
          ? // @ts-expect-error unchecked index access
            source.branches?.find((branch) => branch.key === 'WBdefaultOption')
              ?.nextStepId
          : null,
    })
  }

  updateNode({
    definitionId,
    stepId,
    update,
    skipAddingToHistory = false,
  }: {
    definitionId: string
    stepId: string
    update: Partial<WorkflowDefinitionModel['workflowSteps'][string]>
    // TODO(WFA): This allows us to not add changes to the history
    // when side panel changes are made.
    skipAddingToHistory?: boolean
  }) {
    const definition = this.definitionsCollection.get(definitionId)?.serialize()

    if (!definition) {
      return
    }

    const workflowSteps = { ...definition.workflowSteps }

    const updatedStep = {
      ...workflowSteps[stepId],
      ...update,
    }

    // @ts-expect-error unchecked index access
    workflowSteps[stepId] = updatedStep

    const updatedWorkflowDefinition = new WorkflowDefinitionModel({
      ...definition,
      workflowSteps,
    })

    this.updateLocalWorkflow(updatedWorkflowDefinition, skipAddingToHistory)
  }

  hasChanges(definitionId: string) {
    return this.definitionsBeforeModificationsById.has(definitionId)
  }

  isStepAfterBusinessHours(definitionId: string, stepId: string): boolean {
    const previousSteps = this.getPreviousSteps(definitionId, stepId)
    const isAfterBusinessHours = previousSteps.some(
      (node) =>
        this.getDefinitionById(definitionId)?.workflowSteps[node.id]?.branches?.some(
          (branch) =>
            branch.key === 'WBafterHours' &&
            (previousSteps.some((n) => n.id === branch.nextStepId) ||
              stepId === branch.nextStepId),
        ),
    )

    return isAfterBusinessHours
  }

  private getConfiguration(
    stepDefinitionId: StepDefinitionId,
    phoneNumberId: string,
    workflowStepId: string,
    definitionId: string,
  ): WorkflowDefinitionModel['configuration'] {
    if (stepDefinitionId === 'WSDringUsers') {
      const newRingOrder = this.root.ringOrder.addRingOrderToCollection(phoneNumberId)

      if (!newRingOrder) {
        throw new Error('Failed to create ring order')
      }

      return [
        {
          variableKey: 'WVringOrderId',
          value: newRingOrder.id,
        },
      ]
    }

    if (stepDefinitionId === 'WSDphoneMenu') {
      return [
        {
          variableKey: 'WVphoneMenuDefaultDestination',
          // @ts-expect-error unchecked index access
          value: this.getVoicemailConfiguration({
            definitionId,
            workflowStepId,
            phoneNumberId,
          })[0].value,
        },
      ]
    }

    if (stepDefinitionId === 'WSDvoicemail') {
      return this.getVoicemailConfiguration({
        definitionId,
        workflowStepId,
        phoneNumberId,
      })
    }

    return []
  }

  private getVoicemailConfiguration(
    args: {
      phoneNumberId: string
    } & (
      | {
          definitionId: string
          workflowStepId: string
        }
      | { type: 'afterHours' | 'duringHours' }
    ),
  ) {
    const phoneNumber = this.root.organization.phoneNumber.collection.get(
      args.phoneNumberId,
    )

    if ('definitionId' in args && 'workflowStepId' in args) {
      return [
        {
          variableKey: 'WVvoicemailUrl',
          value:
            (this.isStepAfterBusinessHours(args.definitionId, args.workflowStepId)
              ? phoneNumber?.awayVoicemailUrl
              : phoneNumber?.voicemailUrl) ?? '',
        },
      ]
    }

    return [
      {
        variableKey: 'WVvoicemailUrl',
        value:
          (args.type === 'duringHours'
            ? phoneNumber?.voicemailUrl
            : phoneNumber?.awayVoicemailUrl) ?? '',
      },
    ]
  }

  async fetchDefinitionsForEntityIdsIfNeeded(
    entityIds: string[],
    localDefinitionsForEntityIds: WorkflowDefinitionModel[],
  ) {
    const entityIdsToFetch = entityIds.filter(
      (entityId) => !this.fetchedDefinitionsEntityIds.has(entityId),
    )
    if (entityIdsToFetch.length === 0) {
      return
    }

    // We want to fetch the remote definitions associated with the entity ids
    // at least once per session to make sure we have the latest data
    const remoteDefinitionsForEntityIds =
      await this.fetchDefinitionsForEntityIds(entityIdsToFetch)

    entityIdsToFetch.forEach((entityId) => {
      runInAction(() => {
        this.fetchedDefinitionsEntityIds.add(entityId)
      })
    })

    // TODO(WFA-812)
    // Remove the local entities that have been deleted in the remote for
    // the entity ids that we just fetched
    localDefinitionsForEntityIds.forEach((localDefinition) => {
      if (!entityIdsToFetch.includes(localDefinition.entityId)) {
        return
      }

      const remoteDefinition = remoteDefinitionsForEntityIds.find(
        (remoteDefinition) => remoteDefinition.id === localDefinition.id,
      )

      if (!remoteDefinition) {
        this.definitionsCollection.delete(localDefinition.id)
      }
    })

    remoteDefinitionsForEntityIds.forEach((remoteDefinition) => {
      this.handleRemoteDefinition(remoteDefinition)
    })
  }

  private async fetchStepDefinitionFromRemoteIfNeeded(
    stepDefinitionId: string,
    localStepDefinition: WorkflowStepDefinitionModel | null,
  ) {
    // We want to fetch the remote step definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedStepDefinitionIds.has(stepDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteStepDefinition = await this.fetchStepDefinitionById(stepDefinitionId)
    runInAction(() => {
      this.fetchedStepDefinitionIds.add(stepDefinitionId)
    })

    if (localStepDefinition && !remoteStepDefinition) {
      // If the remote step definition has been deleted, delete the local one too
      this.stepDefinitionsCollection.delete(localStepDefinition.id)
    } else if (localStepDefinition && remoteStepDefinition) {
      // If both local and remote exist, update local step definition with remote data
      localStepDefinition.deserialize(remoteStepDefinition)
    } else if (!localStepDefinition && remoteStepDefinition) {
      // If the step definition exists only on remote, add it to the local collection
      this.stepDefinitionsCollection.put(
        new WorkflowStepDefinitionModel(remoteStepDefinition),
      )
    }
  }

  private async fetchTriggerDefinitionFromRemoteIfNeeded(
    triggerDefinitionId: string,
    localTriggerDefinition: WorkflowTriggerDefinitionModel | null,
  ) {
    // We want to fetch the remote trigger definition at least once per session
    // to make sure we have the latest data
    const alreadyFetched = this.fetchedTriggerDefinitionIds.has(triggerDefinitionId)
    if (alreadyFetched) {
      return
    }

    const remoteTriggerDefinition =
      await this.fetchTriggerDefinitionById(triggerDefinitionId)
    runInAction(() => {
      this.fetchedTriggerDefinitionIds.add(triggerDefinitionId)
    })

    if (localTriggerDefinition && !remoteTriggerDefinition) {
      // If the remote trigger definition has been deleted, delete the local one too
      this.triggerDefinitionsCollection.delete(localTriggerDefinition.id)
    } else if (localTriggerDefinition && remoteTriggerDefinition) {
      // If both local and remote exist, update local trigger definition with remote data
      localTriggerDefinition.deserialize(remoteTriggerDefinition)
    } else if (!localTriggerDefinition && remoteTriggerDefinition) {
      // If the trigger definition exists only on remote, add it to the local collection
      this.triggerDefinitionsCollection.put(
        new WorkflowTriggerDefinitionModel(remoteTriggerDefinition),
      )
    }
  }

  private async fetchDefinitionsForEntityIds(entityIds: string[]) {
    const response = await this.root.transport.workflow.definitions.list({
      orgId: this.root.organization.getCurrentOrganization().id,
      userId: this.root.user.getCurrentUser().id,
      maxResults: 50,
      entityIds,
    })

    return response.data
  }

  private async fetchStepDefinitionById(id: string) {
    const response = await this.root.transport.workflow.stepDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private async fetchTriggerDefinitionById(id: string) {
    const response = await this.root.transport.workflow.triggerDefinitions.get(id)

    if (!response) {
      return
    }

    return response
  }

  private updateOrCreateRingOrders(definition: WorkflowDefinitionModel) {
    return Promise.all(
      Object.values(definition.workflowSteps).map((step) => {
        if (step.definitionId !== 'WSDringUsers') {
          return
        }

        const ringOrderId = step.configuration?.find(
          (c) => c.variableKey === 'WVringOrderId',
        )?.value

        if (typeof ringOrderId !== 'string') {
          return
        }

        const ringOrder = this.root.ringOrder.getById(ringOrderId)

        if (!ringOrder) {
          return
        }

        return this.root.ringOrder.update(ringOrder)
      }),
    )
  }

  private deleteRemovedRingOrdersFromRemote(
    definition: WorkflowDefinitionModel,
    definitionBeforeModifications: RawWorkflowDefinition,
  ) {
    Object.values(definitionBeforeModifications.workflowSteps).forEach((step) => {
      // We only care about ringUsers steps, so ignore all other steps
      if (step.definitionId !== 'WSDringUsers') {
        return
      }

      // Grab the ringOrderId from the configuration of the step before the modifications
      const previousRingOrderId = step.configuration?.find(
        (c) => c.variableKey === 'WVringOrderId',
      )?.value

      if (typeof previousRingOrderId !== 'string') {
        // Just type checking to make TS happy
        return
      }

      const currentStep = definition.workflowSteps[step.id]

      // If the step was removed, delete the ring order associated to it
      if (!currentStep) {
        void this.root.ringOrder.delete(previousRingOrderId)

        return
      }

      // Grab the ringOrderId from the configuration of the current step
      const currentRingOrderId = currentStep.configuration?.find(
        (c) => c.variableKey === 'WVringOrderId',
      )?.value

      // If the ringOrderId has changed (which can happen if the ring order step is replaced with another ring order step),
      // delete the previous ring order
      if (
        typeof currentRingOrderId === 'string' &&
        currentRingOrderId === previousRingOrderId
      ) {
        return
      }

      void this.root.ringOrder.delete(previousRingOrderId)
    })
  }

  private handleRemoteDefinition(definition: RawWorkflowDefinition) {
    if (this.definitionsBeforeModificationsById.has(definition.id)) {
      // If the local version has been modified but not published, instead of replacing it with
      // the remote version, we update the pre-modified version with the latest remote data.
      // At this point, the user can either:
      //  - discard (and be back to the latest remote data); or
      //  - publish (and replace the remote data with the modified one)
      this.definitionsBeforeModificationsById.set(definition.id, definition)

      // However, if the enabled state has changed, then we do need to  update the local version
      // to have the new enabled value.
      const localDefinition = this.definitionsCollection.get(definition.id)
      if (localDefinition && definition.enabled !== localDefinition.enabled) {
        this.definitionsCollection.put(
          new WorkflowDefinitionModel({
            ...localDefinition.serialize(),
            enabled: definition.enabled,
          }),
        )
      }
      return
    }

    this.definitionsCollection.put(new WorkflowDefinitionModel(definition))
  }

  private getAllChildrenSteps(
    definitionId: string,
    workflowStep: WorkflowDefinitionModel['workflowSteps'][string],
  ) {
    const childrenSteps: WorkflowDefinitionModel['workflowSteps'][string][] = []

    workflowStep.branches?.forEach((branch) => {
      const nextStep = this.getWorkflowStepById(definitionId, branch.nextStepId)

      if (!nextStep) {
        return
      }

      childrenSteps.push(nextStep)

      if (nextStep.definitionId === 'WSDgoTo') {
        return
      }

      childrenSteps.push(...this.getAllChildrenSteps(definitionId, nextStep))
    })

    return childrenSteps
  }

  private getSources(
    definitionId: string,
    stepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string][] {
    const definition = this.definitionsCollection.get(definitionId)

    if (!definition) {
      return []
    }

    return Object.values(definition.workflowSteps).filter(
      (step) => step.branches?.some((branch) => branch.nextStepId === stepId),
    )
  }

  getPreviousSteps(
    definitionId: string,
    startingStepId: string,
  ): WorkflowDefinitionModel['workflowSteps'][string][] {
    const previousSteps: WorkflowDefinitionModel['workflowSteps'][string][] = []

    const populatePreviousSteps = (stepId: string) => {
      this.getSources(definitionId, stepId).forEach((s) => {
        previousSteps.push(s)

        if (s.definitionId === 'WSDgoTo') {
          return
        }

        populatePreviousSteps(s.id)
      })
    }

    populatePreviousSteps(startingStepId)

    return previousSteps
  }

  upsertDefinition(definition: RawWorkflowDefinition) {
    this.definitionsCollection.put(new WorkflowDefinitionModel(definition))
  }

  private load() {
    return Promise.all([
      this.definitionsCollection.performQuery((repo) => repo.all()),
      this.stepDefinitionsCollection.performQuery((repo) => repo.all()),
      this.triggerDefinitionsCollection.performQuery((repo) => repo.all()),
    ])
  }

  private subscribeToWebSocket() {
    return this.root.transport.onNotificationData.subscribe((data) => {
      switch (data.type) {
        case 'phone-number-update': {
          // We only need to handle the case where a phone number was opted out of the beta.
          // If a phone number was opted in, then the CallFlow component (which will be shown
          // now that incomingCallWorkflowEnabled is true) will take care of fetching the
          // relevant workflow definition data
          if (!data.phoneNumber.settings?.incomingCallWorkflowEnabled) {
            const entityId = data.phoneNumber.id

            const localDefinitionsForEntityId = this.definitionsCollection.list.filter(
              (definition) => definition.entityId === entityId,
            )
            this.definitionsCollection.deleteBulk(localDefinitionsForEntityId)
            this.fetchedDefinitionsEntityIds.delete(entityId)
          }
          break
        }
        case 'workflow-definition-create': {
          this.definitionsCollection.put(new WorkflowDefinitionModel(data.definition))
          break
        }
        case 'workflow-definition-update': {
          this.handleRemoteDefinition(data.definition)
          break
        }
        case 'workflow-definition-delete': {
          // No op for now
          // TODO(WFA-812)
          break
        }
      }
    })
  }

  createWorkflowDefinition(definition: RawWorkflowDefinition) {
    return this.root.transport.workflow.definitions.post(definition)
  }
  updateWorkflowDefinition(definition: RawWorkflowDefinition) {
    return this.root.transport.workflow.definitions.put(definition.id, definition)
  }
  deleteWorkflowDefinition(definitionId: string) {
    return this.root.transport.workflow.definitions.delete(definitionId)
  }
}
