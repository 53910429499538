import { makeAutoObservable } from 'mobx'

import { logError } from '@src/lib/log'
import type Service from '@src/service'

interface MessageData {
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents -- FIXME: Fix this ESLint violation!
  type: 'requestToken' | 'logoutAndReset' | string
}

class WorkerService {
  constructor(private service: Service) {
    this.subscribe()

    makeAutoObservable(this)
  }

  subscribe() {
    this.service.socketInstance.addEventListener(
      'message',
      // eslint-disable-next-line @typescript-eslint/no-misused-promises -- UXP-3744 - Fix Promise-related ESLint issues
      async (event: MessageEvent<string>) => {
        const data: MessageData = JSON.parse(event.data) as MessageData

        if (data.type === 'requestToken') {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          this.handleRequestToken()
        }

        if (data.type == 'logoutAndReset') {
          this.handleLogoutAndReset()
        }
      },
    )
  }

  private handleLogoutAndReset() {
    this.service.clearAllAndRestart().catch(logError)
  }

  private async handleRequestToken() {
    try {
      const token = await this.service.authV2.getAccessToken()

      this.service.socketInstance?.postMessage(this.prepareMessage('newToken', { token }))
    } catch (err) {
      logError(err)
      this.service.socketInstance?.postMessage(
        this.prepareMessage('newTokenRejected', { err }),
      )
    }
  }

  private prepareMessage(type: string, payload: unknown) {
    return JSON.stringify({ type, payload })
  }
}

export default WorkerService
