import { observer } from 'mobx-react-lite'
import { useCallback, useEffect } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { useAppStore } from '@src/app/context'
import { logError } from '@src/lib/log'
import { LoadingIndicator } from '@ui/LoadingIndicator'

import type UniversalLoginAppState from './UniversalLoginAppState'
import * as styles from './UniversalLoginAuthorize.css'

const UniversalLoginAuthorize = () => {
  const { service, toast } = useAppStore()
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()

  const handleRedirectCallback = useCallback(async () => {
    try {
      const result =
        await service.authV2.auth0Client?.handleRedirectCallback<UniversalLoginAppState>()
      await service.authV2.checkIfAuthenticated()

      if (result?.appState) {
        const inviteToken = result.appState.inviteToken
        const referralCode = result.appState.referralCode
        const phoneNumber = result.appState.phoneNumber

        if (inviteToken) {
          await service.authV2.acceptInvite(inviteToken)
        }

        if (referralCode) {
          // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
          service.authV2.acceptReferral(referralCode)
        }

        if (phoneNumber) {
          // eslint-disable-next-line react-compiler/react-compiler -- UXP-3732 - Fix React Compiler errors
          service.authV2.phoneNumber = phoneNumber
        }
      }

      service.authV2.sessionStartedOn = Date.now()

      navigate('/')
    } catch (error) {
      logError(error)

      if (service.authV2.isClockSkewError(error)) {
        service.authV2.showLogoutReason('machine-clock-skew')
        toast.showError('Session expired. Please try again.')
      } else {
        toast.showError('Something went wrong. Please try again.')
      }

      navigate('/login')
    }
  }, [navigate, service.authV2, toast])

  useEffect(() => {
    const code = searchParams.get('code')
    const error = searchParams.get('error')
    const errorDescription = searchParams.get('error_description')

    if (errorDescription || error) {
      toast.showError(errorDescription || error)
    }

    if (!code) {
      navigate('/login')
      return
    }

    // eslint-disable-next-line @typescript-eslint/no-floating-promises -- UXP-3744 - Fix Promise-related ESLint issues
    handleRedirectCallback()
  }, [searchParams, navigate, handleRedirectCallback, toast])

  return (
    <div className={styles.root}>
      <LoadingIndicator size={30} variant="circular" />
    </div>
  )
}

export default observer(UniversalLoginAuthorize)
